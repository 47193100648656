import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Subscription } from 'rxjs';
import { environment } from '@environment/environment';
import { SharedService } from 'app/store/Shared/services/SharedServices';

@Component({
  selector: 'app-chat',
  templateUrl: './ui/chat.component.html',
  styleUrls: ['./ui/chat.component.scss'],
})
export class Chat implements OnInit {
  subs: Subscription[] = [];
  socket: any;
  roomId: any = null;
  userId: any = null;
  messages: any = [];
  BASE_URL = `${environment.API_BASE_URL}`;
  oldMessages: any[] = [];
  page: number = 1;
  totalPages: number = 1;
  messagesLoading: boolean = false;

  @ViewChild('textInput', { static: false }) textInput: ElementRef;
  @ViewChild('messagesContainer', { static: false }) messagesContainer: ElementRef;

  constructor(
    private sharedService: SharedService,
  ) {}

  ngOnInit(): void {
    this._loadMessages();
    this._createSocket();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _loadMessages() {
    this.messagesLoading = true;
    this.sharedService.getMessages({page: this.page, perPage: 10, sortOrder: 'desc', sortKey: 'createdAt'})
      .subscribe((data) => {
        this.totalPages = data.headers.get('x-total-pages');
        data.body.data.map((mgs: any) => this.oldMessages.unshift(mgs));
        console.log(this.oldMessages);
        this.messagesLoading = false;
      })
  }

  private _createSocket() {
    let item = localStorage.getItem('firmderWebUser');
    if (item) {
      let info = JSON.parse(item);
      let token = info.token;
      this.socket = io(`${this.BASE_URL}/chat/client`, {
        auth: {
          token: `Bearer ${token}`
        }
      });
    }

    this.socket.on("authenticated", (data: any) => {
      this.roomId = data.userData.email;
      this.userId = data.userData.id;
      this.socket.emit("joinRoom", this.roomId);
    });

    this.socket.on("message", (data: any) => {
      const messageClass = data.userData.id === this.userId ? 'self' : 'client';
      const avatarUrl = data.userData.avatarUrl;
      const initials = data.userData.name ? data.userData.name.split(' ').map((n: any) => n[0]).join('') : '';
      const name = data.userData.name;

      this.messages.push({
        message: data.message,
        self: messageClass === 'self',
        avatar: avatarUrl,
        initials: initials,
        name: name
      });

      this.scrollToBottom();
    });
  }

  public sendMsg() {
    if (this.textInput.nativeElement.value !== '') {
      this.socket.emit('message', { roomId: this.roomId, senderId: this.userId, message: this.textInput.nativeElement.value });
      this.textInput.nativeElement.value = "";
    }
  }


  private scrollToBottom() {
      try {
        setTimeout(() => {
          this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
        }, 10);
      } catch(err) { }
  }

  public handleScroll(event: any) {
    if (event.target.scrollTop < 100 && !this.messagesLoading && this.page < this.totalPages) {
      this.page++;
      this._loadMessages();
    }
  }

}
