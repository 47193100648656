import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { environment } from '@environment/environment';

import {
  getCompanyById,
  getCompanyByIdSuccess,
  getCompanyByIdFailure,
  getServices,
  getServicesSuccess,
  getPaymentMethods,
  getPaymentMethodsSuccess,
  addPayment,
  addPaymentSuccess,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-marketplace-comission',
  templateUrl: './ui/marketplace-comission.component.html',
  styleUrls: ['./ui/marketplace-comission.component.scss'],
})
export class MarketplaceComission implements OnInit {
  subs: Subscription[] = [];
  id: string | null = null;
  company: any;
  userId: string | null = null;
  commissionServiceId: string | null = null;
  paymentMethodId: string | null = null;

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.id =
      this.route && this.route.snapshot && this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(getCompanyById({
      id: this.id
    }));
    this._getUserInfo();
    this._getCompanyListener();
    this._getServices();
    this._getPaymentMethods();
    this._paymentSuccessListener();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getUserInfo() {
    let userInfo = localStorage.getItem('firmderWebUser');
    if (userInfo) {
      this.userId = JSON.parse(userInfo).user.id;
    } else {
      this.router.navigate([`/account/marketplace/${this.id}`]);
    }
  }

  private _getCompanyListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdSuccess.type))
        .subscribe(({ data }: any) => {
          if (data && data.buyersOfferAgreements) {
            if (data.buyersOfferAgreements.findIndex((e: any) => e === this.userId) !== -1) {
              this.company = data;
            } else {
              this.router.navigate([`/account/marketplace/${this.id}`]);
            }
          } else {
            this.router.navigate([`/account/marketplace/`]);
          }
        })
    );

    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdFailure.type))
        .subscribe(({ data }: any) => {
          this.router.navigate([`/account/marketplace/`]);
        })
    );
  }

  private _getServices() {
    this.store.dispatch(getServices({data: {}}));

    this.subs.push(
      this.actions
        .pipe(ofType(getServicesSuccess.type))
        .subscribe(({data}: any) => {
          this.commissionServiceId = data.filter((e: any) => e.title === 'Company Sale Commission')[0].id;
        })
      )
  }

  private _getPaymentMethods() {
    this.store.dispatch(getPaymentMethods());

    this.subs.push(
      this.actions
        .pipe(ofType(getPaymentMethodsSuccess.type))
        .subscribe(({data}: any) => {
          this.paymentMethodId = data.find((e: any) => e.isDefault)!.paymentMethodId;
        })
      )
  }

  private _paymentSuccessListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(addPaymentSuccess.type))
        .subscribe(({data}: any) => {
          this.router.navigate([`/account/marketplace/${this.company.id}/documents`]);
        })
      )
  }

  public onBuyClick() {
    const data = {
      companyId: this.company.id,
      paymentMethodId: this.paymentMethodId,
      serviceId: this.commissionServiceId
    }
    this.store.dispatch(addPayment({data}));
  }
}
