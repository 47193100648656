import { Component, TypeDecorator } from '@angular/core';
import { Route, Routes } from '@angular/router';
import { AddPaymentMethodPage } from 'pages/Dashboard/AddPaymentMethodPage';
import { AddCardPage } from 'pages/Dashboard/AddCardPage';
import { LoginPageComponent } from 'pages/LoginPage';
import { MainPageComponent } from 'pages/MainPage';
import { NewPasswordPageComponent } from 'pages/NewPasswordPage';
import { NotFoundPageComponent } from 'pages/NotFoundPage';
import { RegPageComponent } from 'pages/RegPage';
import { ResetPasswordPageComponent } from 'pages/ResetPwdPage/reset-password-page.component';
import { UserCabinetInfoPageComponent } from 'pages/Dashboard/UserCabinetInfoPage/user-cabinet-info-page.component';
import { UserCabinetPageComponent } from 'pages/Dashboard/UserCabinetPage/user-cabinet-page.component';
import { TransactionsPage } from 'pages/Dashboard/TransactionsPage';
import { ConfirmMailPage } from 'pages/Dashboard/ConfirmMailPage/ConfirmMailPage';
import { ServicesCataloguePage } from 'pages/Dashboard/ServicesCataloguePage/ServicesCataloguePage';
import { AddCompanyPage } from 'pages/Dashboard/AddPaymentMethodPage/AddCompanyPage/AddCompanyPage';
import { MyOrdersPage } from 'pages/Dashboard/MyOrdersPage/MyOrdersPage';
import { MyOrdersEdit } from 'pages/Dashboard/MyOrdersPage/edit/MyOrdersEdit';
import { MyOrdersToRegistration } from 'pages/Dashboard/MyOrdersPage/toRegistration/MyOrdersToRegistration';
import { MyCompaniesPage } from 'pages/Dashboard/MyCompaniesPage/MyCompaniesPage';
import { MyCompaniesEditPage } from 'pages/Dashboard/MyCompaniesPage/edit/MyCompaniesEditPage';
import { Chat } from 'pages/Chat/chat.component';
import { Marketplace } from 'pages/Marketplace/marketplace.component';
import { MarketplaceView } from 'pages/Marketplace/view/marketplace-view.component';
import { PutCompanyForSalePage } from 'pages/Dashboard/PutCompanyForSalePage/PutCompanyForSalePage';
import { CompanyPricePage } from 'pages/Dashboard/CompanyPricePage/CompanyPricePage';
import { MarketplaceOffer } from 'pages/Marketplace/offer/marketplace-offer.component';
import { MarketplaceDocuments } from 'pages/Marketplace/documents/marketplace-documents.component';
import { MarketplaceComission } from 'pages/Marketplace/comission/marketplace-comission.component';

export enum AppRoutes {
  NOT_FOUND = 'not_found',
  MAIN_PAGE = 'main',
  REG_PAGE = 'registration',
  LOGIN_PAGE = 'login',
  RESETPWD_PAGE = 'reset-pwd',
  NEWPWD_PAGE = 'new-pwd',
  USER_CABINET_PAGE = 'user-cabinet',
  USER_CABINET_INFO_PAGE = 'user-cabinet-info',
  ADD_PAYMENT_METHOD_PAGE = 'add-payment-method',
  ADD_CARD_PAGE = 'add-card',
  TRANSACTIONS_PAGE = 'transactions',
  CONFIRM_MAIL_PAGE = 'confirm-mail',
  MY_ORDERS = 'my-orders',
  MY_ORDERS_EDIT = 'edit-order',
  MY_COMPANIES = 'my-companies',
  MY_COMPANIES_EDIT = 'my-companies/:id',
  ORDER_TO_REGISTRATION = 'order-to-registration',
  COMPANY_FOR_SALE = 'put-company-for-sale/:id',
  COMPANY_PRICE = 'company-price/:id',

  //services-catalogue
  SERVICES_CATALOGUE_PAGE = 'services-catalogue-page',
  ADD_COMPANY_PAGE = 'add-company-page',
  ADD_COMPANY_PAGE_ID = 'add-company-page-id',

  //chat
  CHAT_PAGE = 'chat',

  //marketplace
  MARKETPLACE_PAGE = 'marketplace',
  MARKETPLACE_VIEW_PAGE = 'marketplace-view',
  MARKETPLACE_OFFER_PAGE = 'marketplace-offer',
  MARKETPLACE_DOCUMENTS_PAGE = 'marketplace-documents',
  MARKETPLACE_COMISSION_PAGE = 'marketplace-comission',
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.NOT_FOUND]: '**',
  [AppRoutes.MAIN_PAGE]: '',
  [AppRoutes.REG_PAGE]: 'registration',
  [AppRoutes.LOGIN_PAGE]: 'login',
  [AppRoutes.RESETPWD_PAGE]: 'reset-pwd',
  [AppRoutes.NEWPWD_PAGE]: 'new-pwd',
  [AppRoutes.USER_CABINET_PAGE]: 'user-cabinet',
  [AppRoutes.USER_CABINET_INFO_PAGE]: 'user-cabinet-info',
  [AppRoutes.ADD_PAYMENT_METHOD_PAGE]: 'add-payment-method',
  [AppRoutes.ADD_CARD_PAGE]: 'add-card',
  [AppRoutes.TRANSACTIONS_PAGE]: 'transactions',
  [AppRoutes.CONFIRM_MAIL_PAGE]: 'confirm-mail',
  [AppRoutes.MY_ORDERS]: 'my-orders',
  [AppRoutes.MY_ORDERS_EDIT]: 'edit-order',
  [AppRoutes.MY_COMPANIES]: 'my-companies',
  [AppRoutes.MY_COMPANIES_EDIT]: 'my-companies/:id',
  [AppRoutes.ORDER_TO_REGISTRATION]: 'order-to-registration',
  [AppRoutes.COMPANY_FOR_SALE]: 'put-company-for-sale/:id',
  [AppRoutes.COMPANY_PRICE]: 'company-price/:id',

  //services-catalogue
  [AppRoutes.SERVICES_CATALOGUE_PAGE]: 'services-catalog',
  [AppRoutes.ADD_COMPANY_PAGE]: 'add-company',
  [AppRoutes.ADD_COMPANY_PAGE_ID]: 'add-company/:id',

  //chat
  [AppRoutes.CHAT_PAGE]: 'chat',

  //marketplace
  [AppRoutes.MARKETPLACE_PAGE]: 'marketplace',
  [AppRoutes.MARKETPLACE_VIEW_PAGE]: 'marketplace/:id',
  [AppRoutes.MARKETPLACE_OFFER_PAGE]: 'marketplace/:id/offer',
  [AppRoutes.MARKETPLACE_DOCUMENTS_PAGE]: 'marketplace/:id/documents',
  [AppRoutes.MARKETPLACE_COMISSION_PAGE]: 'marketplace/:id/comission',
};

export type RouteProps = {
  component: Component;
  path: string;
};

export const RouteConfig: Record<AppRoutes, Route> = {
  [AppRoutes.NOT_FOUND]: {
    component: NotFoundPageComponent,
    path: RoutePath.not_found,
  },
  [AppRoutes.MAIN_PAGE]: {
    component: MainPageComponent,
    path: RoutePath.main,
  },
  [AppRoutes.REG_PAGE]: {
    component: RegPageComponent,
    path: RoutePath.registration,
  },
  [AppRoutes.LOGIN_PAGE]: {
    component: LoginPageComponent,
    path: RoutePath.login,
  },
  [AppRoutes.RESETPWD_PAGE]: {
    component: ResetPasswordPageComponent,
    path: RoutePath['reset-pwd'],
  },
  [AppRoutes.NEWPWD_PAGE]: {
    component: NewPasswordPageComponent,
    path: RoutePath['new-pwd'],
  },
  [AppRoutes.USER_CABINET_PAGE]: {
    component: UserCabinetPageComponent,
    path: RoutePath['user-cabinet'],
  },
  [AppRoutes.USER_CABINET_INFO_PAGE]: {
    component: UserCabinetInfoPageComponent,
    path: RoutePath['user-cabinet-info'],
  },
  [AppRoutes.ADD_PAYMENT_METHOD_PAGE]: {
    component: AddPaymentMethodPage,
    path: RoutePath['add-payment-method'],
  },
  [AppRoutes.ADD_CARD_PAGE]: {
    component: AddCardPage,
    path: RoutePath['add-card'],
  },
  [AppRoutes.TRANSACTIONS_PAGE]: {
    component: TransactionsPage,
    path: RoutePath['transactions'],
  },
  [AppRoutes.CONFIRM_MAIL_PAGE]: {
    component: ConfirmMailPage,
    path: RoutePath['confirm-mail'],
  },
  [AppRoutes.MY_ORDERS]: {
    component: MyOrdersPage,
    path: RoutePath['my-orders'],
  },
  [AppRoutes.MY_COMPANIES]: {
    component: MyCompaniesPage,
    path: RoutePath['my-companies'],
  },
  [AppRoutes.MY_COMPANIES_EDIT]: {
    component: MyCompaniesEditPage,
    path: RoutePath['my-companies/:id'],
  },
  [AppRoutes.MY_ORDERS_EDIT]: {
    component: MyOrdersEdit,
    path: RoutePath['edit-order'],
  },
  [AppRoutes.ORDER_TO_REGISTRATION]: {
    component: MyOrdersToRegistration,
    path: RoutePath['order-to-registration'],
  },
  [AppRoutes.COMPANY_FOR_SALE]: {
    component: PutCompanyForSalePage,
    path: RoutePath['put-company-for-sale/:id'],
  },
  [AppRoutes.COMPANY_PRICE]: {
    component: CompanyPricePage,
    path: RoutePath['company-price/:id'],
  },

  //services-catalogue
  [AppRoutes.SERVICES_CATALOGUE_PAGE]: {
    component: ServicesCataloguePage,
    path: RoutePath['services-catalogue-page'],
  },
  [AppRoutes.ADD_COMPANY_PAGE]: {
    component: AddCompanyPage,
    path: RoutePath['add-company-page'],
  },
  [AppRoutes.ADD_COMPANY_PAGE_ID]: {
    component: AddCompanyPage,
    path: RoutePath['add-company-page-id'],
  },

  //chat
  [AppRoutes.CHAT_PAGE]: {
    component: Chat,
    path: RoutePath['chat'],
  },

  //marketplace
  [AppRoutes.MARKETPLACE_PAGE]: {
    component: Marketplace,
    path: RoutePath['marketplace'],
  },
  [AppRoutes.MARKETPLACE_VIEW_PAGE]: {
    component: MarketplaceView,
    path: RoutePath['marketplace-view'],
  },
  [AppRoutes.MARKETPLACE_OFFER_PAGE]: {
    component: MarketplaceOffer,
    path: RoutePath['marketplace-offer'],
  },
  [AppRoutes.MARKETPLACE_DOCUMENTS_PAGE]: {
    component: MarketplaceDocuments,
    path: RoutePath['marketplace-documents'],
  },
  [AppRoutes.MARKETPLACE_COMISSION_PAGE]: {
    component: MarketplaceComission,
    path: RoutePath['marketplace-comission'],
  },
};
