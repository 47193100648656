<div
  class="add-company-address"
  style="max-width: 704px"
  [formGroup]="addressForm"
>
  <div class="add-company-title-container">
    <div
      class="add-company-title-container__nav"
      *ngIf="editable !== 'address'"
    >
      <button class="add-company-title-container__back" (click)="prevStep()">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
      <button class="add-company-title-container__next" (click)="nextStep()" [disabled]="!continueButton">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
    </div>
    <h2 class="add-company-title">
      {{ "add-company-page.address.title.1" | translate }}
    </h2>
  </div>
  <div class="add-company-step">
    <div class="add-company-step__text">2/5</div>
  </div>
  <ng-content select="[loading]"></ng-content>

  <div class="add-company-address__content">
    <div class="turn-input">
      <p class="turn-input__text">
        {{'add-company-page.address.iHaveAnAddress' | translate}}
      </p>
      <div class="swicher">
        <input type="checkbox" formControlName="haveAnAddress" />
        <span class="swicher__slider swicher__round"></span>
      </div>
    </div>

    <div class="add-company-address__adresses">
      <div *ngIf="haveAdress" class="add-company-address__adresses__adress">
        <div class="add-company-address__non-active-dropdown">
          <app-dropdown-type-1
            [name]="
              country.value
                ? getCountryName(country.value)
                : ('add-company-page.address.country' | translate)
            "
            [nameDefault]="'add-company-page.address.country' | translate"
            [elements]="filteredCountries"
            [size]="'100%'"
            [color]="'#262A34'"
            (setItemFromButton)="setCountry($event)"
            (settingDropdownOpen)="setDropdown($event)"
            [dropdownOpen]="dropdown == '1' ? true : false"
            [dropdown]="'1'"
            [disabled]="true"
          />
        </div>
        <div
          [ngClass]=" this.addressForm.value.country !== ''? 'add-company-address__active-dropdown' : 'add-company-address__non-active-dropdown'"
        >
          <app-dropdown-type-1
            [name]="
            city.value
              ? getCityName(city.value)
              : ('add-company-page.address.city' | translate)
          "
            [nameDefault]="'add-company-page.address.city' | translate"
            [elements]="cities"
            [size]="'100%'"
            [color]="'#262A34'"
            (setItemFromButton)="setCity($event)"
            (settingDropdownOpen)="setDropdown($event)"
            [dropdownOpen]="dropdown == '5' ? true : false"
            [dropdown]="'5'"
            [disabled]="this.addressForm.value.country === ''"
          />
        </div>
        <div class="add-company-address__input">
          <input
            type="text"
            formControlName="address1"
            [placeholder]="'add-company-page.address.addressLine1' | translate"
          />
        </div>
        <!-- <div class="add-company-address__input">
          <input
            type="text"
            formControlName="address2"
            [placeholder]="'add-company-page.address.addressLine2' | translate"
          />
        </div> -->
        <div class="add-company-address__flex-inputs">
          <div class="add-company-address__input">
            <input
              type="text"
              formControlName="postalCode"
              [placeholder]="'add-company-page.address.postalCode' | translate"
            />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!haveAdress" class="add-company-address__adresses__adress">
      <div class="add-company-address__active-dropdown">
        <p class="add-company-address__active-dropdown__label">
          {{"add-company-page.address.addressSelection" | translate}}
        </p>
        <app-dropdown-type-1
          [name]="virtual.value == '1' ?( 'add-company-page.address.1yearVirtual'| translate )
          : virtual.value == '2' ?( 'add-company-page.address.realOfficeSelection' |translate )
          : ('add-company-page.address.SelectAnAddressOption' | translate) "
          [nameDefault]="'add-company-page.address.SelectAnAddressOption'|translate"
          [elements]="[{ title:'add-company-page.address.1yearVirtual'| translate,
        option: '1' }, { title: 'add-company-page.address.realOfficeSelection' |translate, option: '2' }]"
          [size]="'100%'"
          [color]="'#262A34'"
          (setItemFromButton)="setVirtualAddress($event)"
          (settingDropdownOpen)="setDropdown($event)"
          [dropdownOpen]="dropdown ==
        '2' ? true : false"
          [dropdown]="'2'"
        ></app-dropdown-type-1>
      </div>
      <div [ngClass]="'add-company-address__non-active-dropdown'">
        <p class="add-company-address__active-dropdown__label">
          {{"add-company-page.address.country" | translate}}
        </p>
        <app-dropdown-type-1
          [name]="
            country2.value
              ? getCountryName(country2.value)
              : ('add-company-page.address.country' | translate)
          "
          [nameDefault]="'add-company-page.address.country' | translate"
          [elements]="filteredCountries"
          [size]="'100%'"
          [color]="'#262A34'"
          (setItemFromButton)="setCountry2($event)"
          (settingDropdownOpen)="setDropdown($event)"
          [dropdownOpen]="dropdown == '3' ? true : false"
          [dropdown]="'3'"
          [disabled]="true"
        />
      </div>
      <div
        [ngClass]="this.addressForm.value.country2 !== ''? 'add-company-address__active-dropdown' : 'add-company-address__non-active-dropdown'"
      >
        <p class="add-company-address__active-dropdown__label">
          {{"add-company-page.address.city" | translate}}
        </p>
        <app-dropdown-type-1
          [name]="
            city2.value
              ? getCityName(city2.value)
              : ('add-company-page.address.city' | translate)
          "
          [nameDefault]="'add-company-page.address.city' | translate"
          [elements]="cities"
          [size]="'100%'"
          [color]="'#262A34'"
          (setItemFromButton)="setCity2($event)"
          (settingDropdownOpen)="setDropdown($event)"
          [dropdownOpen]="dropdown == '4' ? true : false"
          [dropdown]="'4'"
          [disabled]="this.addressForm.value.country2 === ''"
        />
      </div>
    </div>
  </div>

  <ng-content select="[price]"></ng-content>
  <button
    class="Button-blue add-company-address__submit-nonActive"
    [ngClass]="continueButton ? 'add-company-address__submit' : ''"
    (click)="submitStructure()"
    [disabled]="!continueButton"
  >
    {{'continue-btn' | translate}}
  </button>
</div>

<button
  type="button"
  class="save-template-button"
  [ngClass]="!continueButton ? 'nonActive' : ''"
  (click)="submitStructure(true)"
  [disabled]="!continueButton"
>{{ "add-company-page.saveToDrafts" | translate }}</button>
