import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from 'app/store/app.state';
import { Order } from 'app/store/Shared/model/SharedModel';
import { Subscription } from 'rxjs';

import {
  deleteOrder,
  deleteOrderSuccess,
  getOrderList,
  getOrderListSuccess,
  setDeleteOrderModal,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-my-orders-page',
  templateUrl: './ui/MyOrdersPage.component.html',
  styleUrls: ['./ui/MyOrdersPage.component.scss'],
})
export class MyOrdersPage {
  tabName: string | null = null;
  myOrders: Order[] = [];
  myTemplates: Order[] = [];
  deleteWindowOpen: boolean = false;

  subs: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private actions: Actions,
    private router: Router
  ) {}

  ngOnInit() {
    this._tabSwitchListener();
    this._deleteOrderSuccessListner();
    this._getOrdersListener();
    this.store.dispatch(getOrderList());

    this.store
      .select((state) => state.shared.ModalWindowDeleteOrder)
      .subscribe((modal) => {
        this.deleteWindowOpen = modal.open;
      });
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _tabSwitchListener() {
    this.subs.push(
      this.store
        .select((state) => state.shared.ordersButtons)
        .subscribe((button) => {
          this.tabName = button;
        })
    );
  }

  private _getOrdersListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(getOrderListSuccess.type))
        .subscribe(({ data }: any) => {
          if (data && data.length) {
            this.myOrders = (data as any).filter((e: Order) => e.state === 2);
            this.myTemplates = (data as any).filter((e: Order) => e.state === 1);
          } else {
            this.myOrders = [];
            this.myTemplates = [];
          }
        })
    );
  }

  private _deleteOrderSuccessListner() {
    this.subs.push(
      this.actions
        .pipe(ofType(deleteOrderSuccess.type))
        .subscribe(({ data }: any) => {
          this.store.dispatch(getOrderList());
        })
    );
  }

  public navigateToCompanyRegistration(entity: Order) {
    // this.router.navigate([`account/add-company/`], {
    //   queryParams: { id: entity.id },
    // });
    this.router.navigate([`account/add-company/${entity.id}`]);
  }

  public navigateToOrder(entity: Order) {
    this.router.navigate([`account/edit-order/`], {
      queryParams: { id: entity.id },
    });
  }

  public openDeleteWindow(id: string) {
    this.store.dispatch(setDeleteOrderModal({ open: true, order: id }));
  }
  public closeDeleteWindow() {
    this.store.dispatch(setDeleteOrderModal({ open: false, order: '' }));
  }

  public deleteOrderMethod() {
    const deleting = this.store
      .select((state) => state.shared.ModalWindowDeleteOrder)
      .subscribe((modal) => {
        if (modal.open) {
          this.store.dispatch(setDeleteOrderModal({ open: false, order: '' }));
          this.store.dispatch(deleteOrder({ id: modal.order }));
        }
        return;
      });
    deleting.unsubscribe();
  }
}
