<div
  class="add-company-structure"
  style="max-width: 704px"
  [formGroup]="structureForm"
>
  <div class="add-company-title-container">
    <div class="add-company-title-container__nav" *ngIf="editable !== 'info'">
      <!-- <button class="add-company-title-container__back" (click)="prevStep()">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button> -->
      <button class="add-company-title-container__next" (click)="nextStep()" [disabled]="!continueButton">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
    </div>
    <h2 class="add-company-title">
      {{ "add-company-page.structure.title.1" | translate }}
      <br />
      {{ "add-company-page.structure.title.2" | translate }}
    </h2>
  </div>
  <div class="add-company-step">
    <div class="add-company-step__text">1/5</div>
  </div>
  <ng-content select="[loading]"></ng-content>
  <a class="pink-link" [href]="jurisdictionalInfoLink" target="_blank">
    <img src="assets/icons/pink-link.svg" alt="link-pic" />
    <p>
      {{'add-company-page.structure.jurisdictional-information' | translate}}
    </p>
  </a>
  <div class="add-company-structure__content">
    <div class="add-company-dropdown">
      <label class="add-company-dropdown__label"
        >{{ 'add-company-page.structure.jurisdictionalForm' | translate
        }}</label
      >
      <app-dropdown-type-1
        [name]="
            structureForm.value.jurisdictionalForm
              ? getStructureName(structureForm.value.jurisdictionalForm)
              : ('add-company-page.structure.addJurisdictionalForm' | translate)
          "
        [nameDefault]="'add-company-page.structure.addJurisdictionalForm' | translate"
        [elements]="jurisdictionalForms"
        [size]="'100%'"
        [color]="'#181A20'"
        (setItemFromButton)="setJurisdictionalForm($event)"
        (settingDropdownOpen)="setDropdown($event)"
        [dropdownOpen]="dropdown == '1' ? true : false"
        [dropdown]="'1'"
      />
    </div>
    <div class="add-company-digits">
      <label class="add-company-digits__label"
        >{{ 'add-company-page.structure.numberOfDirectors' | translate }}</label
      >
      <div class="add-company-digits__buttons">
        <button
          class="add-company-digits__btn"
          (click)="setNumberOfDirectors(1)"
          [ngClass]="structureForm.value.numberOfDirectors == '1' ? 'add-company-digits__btn-selected' : ''"
        >
          1
        </button>
        <button
          class="add-company-digits__btn no_hover"
          (click)="setNumberOfDirectors(2)"
          [ngClass]="structureForm.value.numberOfDirectors == '2' ? 'add-company-digits__btn-selected' : ''"
        >
          2
        </button>
        <button
          class="add-company-digits__btn no_hover"
          (click)="setNumberOfDirectors(3)"
          [ngClass]="structureForm.value.numberOfDirectors == '3' ? 'add-company-digits__btn-selected' : ''"
        >
          3
        </button>
        <button
          class="add-company-digits__btn no_hover"
          (click)="setNumberOfDirectors(4)"
          [ngClass]="structureForm.value.numberOfDirectors == '4' ? 'add-company-digits__btn-selected' : ''"
        >
          4
        </button>
        <button
          class="add-company-digits__btn no_hover"
          (click)="setNumberOfDirectors(5)"
          [ngClass]="structureForm.value.numberOfDirectors == '5' ? 'add-company-digits__btn-selected' : ''"
        >
          5
        </button>
      </div>
    </div>
    <div class="add-company-digits">
      <label class="add-company-digits__label"
        >{{ 'add-company-page.structure.numberOfFounders' | translate }}</label
      >
      <div class="add-company-digits__buttons">
        <button
          class="add-company-digits__btn"
          (click)="setNumberOfFounders(1)"
          [ngClass]="structureForm.value.numberOfFounders == '1' ? 'add-company-digits__btn-selected' : ''"
        >
          1
        </button>
        <button
          class="add-company-digits__btn"
          (click)="setNumberOfFounders(2)"
          [ngClass]="structureForm.value.numberOfFounders == '2' ? 'add-company-digits__btn-selected' : ''"
        >
          2
        </button>
        <button
          class="add-company-digits__btn"
          (click)="setNumberOfFounders(3)"
          [ngClass]="structureForm.value.numberOfFounders == '3' ? 'add-company-digits__btn-selected' : ''"
        >
          3
        </button>
        <button
          class="add-company-digits__btn"
          (click)="setNumberOfFounders(4)"
          [ngClass]="structureForm.value.numberOfFounders == '4' ? 'add-company-digits__btn-selected' : ''"
        >
          4
        </button>
        <button
          class="add-company-digits__btn"
          (click)="setNumberOfFounders(5)"
          [ngClass]="structureForm.value.numberOfFounders == '5' ? 'add-company-digits__btn-selected' : ''"
        >
          5
        </button>
      </div>
    </div>
    <div
      *ngIf="structureForm.get('jurisdictionalForm')?.value &&
             getCapitalValues(structureForm.get('jurisdictionalForm')?.value) as cp"
      class="select-scroller"
    >
      <label class="select-scroller__label"
        >{{ 'add-company-page.structure.AmountOfAuthorizedCapital' | translate
        }}</label
      >
      <div class="select-scroller__text-input">
        <p class="select-scroller__text-input__text">Fix</p>
        <div class="select-scroller__text-input__price">
          <p class="select-scroller__text-input__price__text">€</p>
          <input
            class="select-scroller__text-input__price__input"
            style="width: 100px;"
            type="number"
            [min]="cp.minSumm"
            [max]="cp.maxSumm"
            formControlName="capital"
            [value]="this.structureForm.controls.capital.value"
            id="add-company-structure-capital-amount-text"
            (input)="resizeInput($event)"
            #capital
          />
        </div>
      </div>
      <input
        type="range"
        class="select-scroller__input"
        [min]="cp.minSumm"
        [max]="cp.maxSumm"
        formControlName="capital"
        [value]="this.structureForm.controls.capital.value"
        ng-disabled="cp.type !== 1"
        [ngClass]="{ 'no_hover': cp.type !== 1 }"
        #capital
      />
      <div class="select-scroller__input-buttons">
        <button
          class="select-scroller__input-buttons__btn-plus"
          [ngClass]="{ 'no_hover': cp.type !== 1 }"
          (click)="setCapital('+', '1')"
          [disabled]="cp.type !== 1"
        >
          +
        </button>
        <button
          class="select-scroller__input-buttons__btn-minus"
          [ngClass]="{ 'no_hover': cp.type !== 1 }"
          (click)="setCapital('-', '1')"
          [disabled]="cp.type !== 1"
        >
          -
        </button>
      </div>
    </div>
  </div>
  <ng-content select="[price]"></ng-content>
  <button
    class="Button-blue add-company-structure__submit-nonActive"
    [ngClass]="continueButton ? 'add-company-structure__submit' : ''"
    (click)="submitStructure()"
    [disabled]="!continueButton"
  >
    {{'continue-btn' | translate}}
  </button>
</div>
