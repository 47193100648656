import {Component, Input, SimpleChanges} from '@angular/core';
import {FormArray, FormGroup, FormGroupDirective} from '@angular/forms';
import {Router} from '@angular/router';

import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {CityData, CountryData} from 'app/store/Shared/model/SharedModel';
import {
  getCitiesByCountry,
  getCitiesByCountrySuccess,
  updateTemplate,
  updateTemplateSuccess,
} from 'app/store/Shared/shared.actions';
import {UserInfoProps} from 'app/store/UserInfo/models/userInfo.model';
import {AppState} from 'app/store/app.state';
import {BehaviorSubject, Subscription} from 'rxjs';
import {DropdownType1Eement} from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';
import {Actions, ofType} from '@ngrx/effects';

@Component({
  selector: 'app-add-company-participants',
  templateUrl: './ui/AddCompanyParticipants.html',
  styleUrls: ['./ui/AddCompanyParticipants.scss'],
})
export class AddCompanyParticipants {
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private rootFormGroup: FormGroupDirective,
    private translateService: TranslateService,
    private actions: Actions,
  ) {
  }

  @Input() formGroupName: string = 'directors';
  @Input() bigFormGroupName: string;
  @Input() structureFormGroupName: string;
  userInfo: UserInfoProps | null;
  structureForm: FormGroup;
  participantsForm: FormGroup;
  directorsFormArray: FormArray;
  FoundersFormGroup: FormGroup;
  FoundersFormArray: FormArray;
  countries: CountryData[];
  country: string;
  numberOfDirectors: number[] = [];
  @Input() addDirector: any;

  @Input() nextStep: any;
  @Input() prevStep: any;
  @Input() previewStep: any;

  dropdown: number | string = '';
  editable: string;
  shares: number = 100;

  // continue button
  continueButton: boolean = false;
  disableIsFounder: any = [];

  saveMode: boolean | undefined = false;

  subs: Subscription[] = [];

  ngOnInit() {
    // initialization of root form group
    this.participantsForm = this.rootFormGroup.control.get(
      this.bigFormGroupName
    ) as FormGroup;

    this.structureForm = this.rootFormGroup.control.get(
      this.structureFormGroupName
    ) as FormGroup;

    this.directorsFormArray = this.participantsForm.get(
      this.formGroupName
    ) as FormArray;
    this.FoundersFormGroup = this.rootFormGroup.control.get(
      'foundersGroup'
    ) as FormGroup;
    this.FoundersFormArray = this.FoundersFormGroup.get('legal') as FormArray;

    // setting counties array
    this.store
      .select((state) => state.shared.countries)
      .subscribe((countries) => {
        if (countries) {
          this.countries = countries;
        }
      });
    // setting virtual to undefined when ihaveanaddress switches
    this.participantsForm.valueChanges.subscribe((data) => {
      this.calculateShare();
      this.setContinueBtn();
      this.setDisableIsFounder();
    });

    // editable
    this.store
      .select((state) => state.shared.editableCompanyStep)
      .subscribe((editable) => {
        if (editable) {
          this.editable = editable;
        }
      });

    for (let num = this.structureForm.value.numberOfDirectors; num > 0; num--) {
      this.numberOfDirectors.push(Number(num));
    }
    for (let i = this.directorsFormArray.value.length; i < this.numberOfDirectors.length; i++) {
      this.addDirector();
    }

    this.calculateShare();
    this.setContinueBtn();
    this.setDisableIsFounder();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  setLegalEntity = (index: number) => {
    this.directorsFormArray.controls[index].setValue({
      nameAndLastName: this.directorsFormArray.value[index].nameAndLastName,
      citizenship: this.directorsFormArray.value[index].citizenship,
      passport: this.directorsFormArray.value[index].passport,
      rights: !this.directorsFormArray.value[index].rights,
      founder: this.directorsFormArray.value[index].founder,
    });
  };

  setDirectorsCountry = (index: number, element: string) => {
    this.directorsFormArray.controls[index].setValue({
      ...this.directorsFormArray.value[index],
      citizenship: element,
    });
  };

  deleteDirector = (index: number) => {
    this.directorsFormArray.removeAt(index);
  };

  calculateShare = () => {
    let shares = 100;
    let number = 0;

    // if (this.directorsFormArray) {
    //   for (let i = 0; i < this.directorsFormArray.length; i++) {
    //     let dirShare = this.directorsFormArray.value[i].share ? this.directorsFormArray.value[i].share : 0;
    //     number += Number(dirShare);
    //   }
    // }

    if (this.FoundersFormArray) {
      for (let i = 0; i < this.FoundersFormArray.length; i++) {
        let founderShare = this.FoundersFormArray.value[i].share ? this.FoundersFormArray.value[i].share : 0;
        number += Number(founderShare);
      }
    }

    let amount = shares - number;
    amount > 0
      ? (this.shares = amount)
      : amount == 0
        ? (this.shares = 0)
        : (this.shares = -1);
  };
  setContinueBtn = () => {
    let length = this.directorsFormArray.length;
    let fulfilled = false;
    for (let i = 0; i < length; i++) {
      if (
        !this.directorsFormArray.value[i].nameAndLastName ||
        this.directorsFormArray.value[i].nameAndLastName == '' ||
        !this.directorsFormArray.value[i].citizenship ||
        this.directorsFormArray.value[i].citizenship == '' ||
        !this.directorsFormArray.value[i].passport ||
        this.directorsFormArray.value[i].passport == ''
      ) {
        fulfilled = false;
      } else {
        fulfilled = true;
      }
    }

    this.continueButton = fulfilled;
  };

  setDropdown(drop: string): void {
    this.dropdown = drop;
    console.log(drop);
  }

  // button-back
  buttonBack = () => {
  };

  // submits structure and forwards step
  submitStructure = (saveMode?: boolean) => {
    this.saveMode = saveMode;
    let data = this.prepareValues();
    this.subs.push(
      this.store
        .select((state) => state.shared.currentTemplateId)
        .subscribe((id) => {
          if (id) {
            this.store.dispatch(updateTemplate({
              data: {
                director: data,
                processStep: 5
              },
              id: id
            }));
          }
        })
    )

    this._listenForUpdateSuccess();

    // if (!saveMode) {
    //   this.editable == 'directors' ? this.previewStep() : this.nextStep();
    // }
  };

  private _listenForUpdateSuccess() {
    this.subs.push(
      this.actions
        .pipe(ofType(updateTemplateSuccess))
        .subscribe(() => {
          if (!this.saveMode) {
            this.editable == 'directors' ? this.previewStep() : this.nextStep();
          }
        })
    );
  }

  private prepareValues() {
    return [
      {
        // nationality: this.countries.find(e => e.id === this.directorsFormArray.value[0].citizenship)!.id,
        nationality: this.directorsFormArray.value[0].citizenship,
        passport: this.directorsFormArray.value[0].passport,
        isOwner: this.directorsFormArray.value[0].founder,
        fullname: this.directorsFormArray.value[0].nameAndLastName
      }
    ]
  }

  public getCountryName(id: string) {
    return this.countries.find(c => c.id === id)!.title;
  }

  private setDisableIsFounder() {
    let length = this.directorsFormArray.length;
    for (let i = 0; i < length; i++) {
      this.disableIsFounder[i] = !(this.directorsFormArray.value[i].nameAndLastName && this.directorsFormArray.value[i].nameAndLastName.length);
    }
  }
}
