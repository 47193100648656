<div *ngIf="company" class="marketplace-documents-container">
	<div class="company-title">
		{{ getPrefix(company.titleVariants[0].prefix) }} «{{ company.titleVariants[0].title }}»
	</div>

	<div class="main-container">
		<div class="column">
			<div class="tags">
				<div class="country">Germany</div>
				<div class="service">{{ company.categories?.name }}</div>
				<div class="icons-container">
					<img *ngIf="company?.salesFeatures?.bankAccounts?.length" class="icon" src="assets/icons/coin-background-icon.svg">
					<img *ngIf="company.salesFeatures.isHasLocalDirector" class="icon" src="assets/icons/user-background-icon.svg">
					<img *ngIf="company.salesFeatures.isVerified" class="icon" src="assets/icons/verified-background-icon.svg">
				</div>
			</div>

			<div class="image-container">
				<img [src]="company.companyImageUrl">
			</div>

			<div class="prices-container">
				<div class="price-container">
					<div class="currency">
						{{ company.currency }} 
					</div>
					<div class="price">
						{{ company.priceForSale }}
					</div>
				</div>

				<div class="commission-container">
					<div class="title">
						Comission
					</div>
					<div class="commission-price-container">
						<div class="currency">
							{{ company.currency }} 
						</div>
						<div class="price">
							{{ company.saleCommission }}
						</div>
					</div>
				</div>
			</div>

		</div>
		<div class="column">
			<div class="properties-container">
				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/setting-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Field of activity</div>
						<div *ngFor="let sub of company.categories.subCategories"class="value">{{ sub.name }}</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/timer-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Date of registration</div>
						<div class="value">{{ getRegistrationDate() }}</div>
					</div>
				</div>
				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/project-recording-outline-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Form of company</div>
						<div class="value">{{ getPrefix(company.titleVariants[0].prefix) }}</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/home-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Property</div>
						<div *ngFor="let property of company.salesFeatures.properties" class="value">{{ property.name }}, {{ property.description }}</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/cash-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Revenue, millions</div>
						<div class="value">{{ company.currency }} {{ company.salesFeatures.annualTurnover / 1000000 }}</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/card-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Bank accounts</div>
						<div *ngFor="let bank of company.salesFeatures.bankAccounts" class="value">{{ bank.name }}</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/tick-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Reporting</div>
						<div *ngFor="let statement of company.salesFeatures.financialStatement" class="value">{{ statement.name }}, {{ statement.description }}</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/online-shopping-outlined-icon.svg">
					</div>
					<div class="text-container">
						<div class="title">Selling as a ready-made business</div>
						<div class="value">{{ booleanToYesNo(company.salesFeatures.isReadyBusiness) }}</div>
					</div>
				</div>

				<div class="property-item">
					<div class="property-icon-container">
						<img class="property-icon" src="assets/icons/namecard-outlined-icon-2.svg">
					</div>
					<div class="text-container">
						<div class="title">Possibility of obtaining citizenship</div>
						<div class="value">{{ booleanToYesNo(company.salesFeatures.isCanGetCitizenship) }}</div>
					</div>
				</div>

			</div>
		</div>
	</div>
</div>

<div *ngIf="company" class="marketplace-documents-docs-box">
	<div class="marketplace-documents-docs-container">
		<div class="title">
			Document
		</div>

		<div class="documents-container">
			<ng-container *ngIf="buyer">
				<div *ngFor="let doc of company.saleDocuments" class="document-row">
					<img class="icon" src="assets/icons/documents.svg">
					<div class="document-title" (click)="openLink(doc.url)">
						{{ doc.description }}
					</div>
				</div>
			</ng-container>
			<ng-container *ngIf="!buyer">
				<div *ngFor="let doc of company.saleDocumentsPreview" class="document-row">
					<img class="icon" src="assets/icons/documents.svg">
					<div class="document-title" (click)="openLink(doc.url)">
						{{ doc.description }}
					</div>
				</div>
			</ng-container>
		</div>
	</div>

	<button class="buy-button-container" (click)="onBuyClick()">Buy</button>
</div>
