<div *ngIf="companies" class="marketplace-container">
	<div class="marketplace-items-container">
		<div *ngFor="let row of companies" class="marketplace-row">
			<div *ngFor="let company of row" class="marketplace-item" (click)="onOpenCompany(company)">
				<div class="marketplace-item-inner-container">
					<div class="image-container">
						<img [src]="company.companyImageUrl">
					</div>
					<div class="info-container">
						<div class="title-container">
							<div class="title">
								{{ getPrefix(company.titleVariants[0].prefix) }} «{{ company.titleVariants[0].title }}»
							</div>
							<div class="icons-container">
								<img *ngIf="true" class="icon" src="assets/icons/coin-background-icon.svg">
								<img *ngIf="company.salesFeatures.isHasLocalDirector" class="icon" src="assets/icons/user-background-icon.svg">
								<img *ngIf="true" class="icon" src="assets/icons/verified-background-icon.svg">
							</div>
						</div>

						<div class="price-container">
							<div class="country">Germany</div>
							<div class="service">Consulting</div>
							<div class="price">{{ company.currency }} {{ company.priceForSale }}</div>
						</div>

						<div class="services-container">
							<div class="service-row">
								<div *ngIf="company.salesFeatures.isHasProperty" class="service-item">
									<img class="service-icon" src="assets/icons/money-bag-outlined-icon.svg">
									property
								</div>
								<div *ngIf="true" class="service-item">
									<img class="service-icon" src="assets/icons/coin-outlined-icon.svg">
									Check
								</div>
								<div *ngIf="company.companyAddress.type === 2" class="service-item">
									<img class="service-icon" src="assets/icons/location-outlined-icon.svg">
									Office
								</div>
							</div>
							<div *ngIf="company.salesFeatures.annualTurnover > 0" class="service-row">
								<div class="service-item">
									<img class="service-icon" src="assets/icons/paper-outlined-icon.svg">
									Active
								</div>
								<div *ngIf="company.salesFeatures.isCanGetCitizenship" class="service-item">
									<img class="service-icon" src="assets/icons/namecard-outlined-icon.svg">
									Citizenship
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="marketplace-filter-container">
		<form class="input-container">
			<img class="service-icon" src="assets/icons/search-outlined-icon.svg">
			<input type="text" placeholder="search" [formControl]="searchControl" #searchInput>
		</form>

		<form class="filter-container" [formGroup]="filterForm">
			<div *ngIf="filterStep === 1" class="field-with-label">
				<div class="label">Price €</div>
				<div class="price-container" (click)="filterStep = 2">
					{{ filterForm.get('priceForSaleFrom')!.value }} - {{ filterForm.get('priceForSaleTo')!.value }}
				</div>
			</div>

			<div *ngIf="filterStep === 2" class="field-with-label">
				<div class="label">Price from</div>
				<input class="price-container" type="number" min="0" max="100000" step="10" formControlName="priceForSaleFrom">
			</div>
			<div *ngIf="filterStep === 2" class="field-with-label">
				<div class="label">Price to</div>
				<input class="price-container" type="number" min="0" max="100000" step="10" formControlName="priceForSaleTo">
			</div>


			<div *ngIf="filterStep === 1" class="checkbox-container">
				<div class="checkbox-title">Ready business with things</div>
				<input type="checkbox" formControlName="isHasProperty">
			</div>
			<div *ngIf="filterStep === 1" class="checkbox-container">
				<div class="checkbox-title">The account is open</div>
				<input type="checkbox" formControlName="isHasBankAccounts">
			</div>
			<div *ngIf="filterStep === 1" class="checkbox-container">
				<div class="checkbox-title">Zero balance</div>
				<input type="checkbox" formControlName="isZeroBalance">
			</div>
			<div *ngIf="filterStep === 1" class="checkbox-container">
				<div class="checkbox-title">Possibility of obtaining citizenship after purchase</div>
				<input type="checkbox" formControlName="isCanGetCitizenship">
			</div>
			<div *ngIf="filterStep === 1" class="checkbox-container">
				<div class="checkbox-title">Local Director</div>
				<input type="checkbox" formControlName="isHasLocalDirector">
			</div>

			<button *ngIf="filterStep === 1" class="button-container" (click)="onFilterSubmit()">Search</button>
			<button *ngIf="filterStep === 2" class="button-container" (click)="checkPriceValues()">Select</button>
		</form>
	</div>
</div>