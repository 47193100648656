<div class="add-company-prewiew">
  <!-- <div class="add-company-title-container" style="margin-top: 20px">
    <div class="add-company-title-container__nav" style="top: -20px">
      <button class="add-company-title-container__back" (click)="prevStep()">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
    </div>
  </div> -->
  <div class="add-company-prewiew__content">
    <div class="add-company-prewiew__content__1">
      <!--REG COUNTRY-->
      <div class="add-company-prewiew-data" [formGroup]="addressForm">
        <!-- <button
          class="add-company-prewiew-data__edit-btn"
          (click)="editJurisdiction()"
        >
          <img src="/assets/icons/pencil.svg" alt="" />
        </button> -->
        <div class="add-company-prewiew-data__block">
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.regCountry' | translate}}
          </p>
          <p
            *ngIf="addressForm.value.country"
            class="add-company-prewiew-data__block__text"
          >
            {{getCountryName(addressForm.value.country)}}
          </p>
          <p
            *ngIf="!addressForm.value.country"
            class="add-company-prewiew-data__block__text-red"
          >
            {{'empty' | translate}}
          </p>
        </div>
      </div>
      <!--REG COUNTRY-->
      <!--COMPANY STRUCTURE-->
      <div class="add-company-prewiew-data" [formGroup]="structureForm">
        <button class="add-company-prewiew-data__edit-btn" (click)="editInfo()">
          <img src="/assets/icons/pencil.svg" alt=""/>
        </button>

        <div class="add-company-prewiew-data__block">
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.form' | translate}}
          </p>
          <p
            *ngIf="structureForm.value.jurisdictionalForm"
            class="add-company-prewiew-data__block__text"
          >
            {{ formIdToName(structureForm.value.jurisdictionalForm) }}
          </p>
          <p
            *ngIf="!structureForm.value.jurisdictionalForm"
            class="add-company-prewiew-data__block__text-red"
          >
            {{'emty' | translate}}
          </p>
        </div>
        <div class="add-company-prewiew-data__block">
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.numberOfDirectors' | translate}}
          </p>
          <p class="add-company-prewiew-data__block__text">
            {{structureForm.value.numberOfDirectors}}
          </p>
        </div>
        <div class="add-company-prewiew-data__block">
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.numberOfFounders' | translate}}
          </p>
          <p class="add-company-prewiew-data__block__text">
            {{structureForm.value.numberOfFounders}}
          </p>
        </div>
        <div class="add-company-prewiew-data__block">
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.capital' | translate}}
          </p>
          <div class="add-company-prewiew-data__block-input-container">
            <p>€</p>
            <input
              *ngIf="editStructure"
              class="add-company-prewiew-data__block__input"
              type="text"
              [formControlName]="'capital'"
            />
            <p
              *ngIf="!editStructure"
              class="add-company-prewiew-data__block__text"
            >
              {{structureForm.value.capital}}
            </p>
          </div>
        </div>
      </div>
      <!--CONPANY STRUCTURE-->
      <!--COMPANY ADDRESS-->
      <div class="add-company-prewiew-data" [formGroup]="addressForm">
        <button
          class="add-company-prewiew-data__edit-btn"
          (click)="editOffice()"
        >
          <img src="/assets/icons/pencil.svg" alt=""/>
        </button>


        <div
          class="add-company-prewiew-data__block"
          *ngIf="!addressForm.value.haveAnAddress"
        >
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.variant' | translate}}
          </p>
          <p
            class="add-company-prewiew-data__block__text"
          >
            {{ addressForm.value.real == '2'
            ? ('add-company-page.preview.realOfficeCost' | translate)
            : ('add-company-page.preview.virtualCost' | translate)
            }}
          </p>
        </div>
        <div
          *ngIf="addressForm.value.real === '2'"
          class="add-company-prewiew-data__block"
        >
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.country' | translate}}
          </p>
          <p class="add-company-prewiew-data__block__text">
            {{getCountryName(addressForm.value.country2)}}
          </p>
        </div>
        <div
          *ngIf="addressForm.value.real == '2'"
          class="add-company-prewiew-data__block"
        >
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.city' | translate}}
          </p>
          <p
            *ngIf="addressForm.value.city2"
            class="add-company-prewiew-data__block__text"
          >
            {{getCityName(addressForm.value.city2)}}
          </p>
          <p
            *ngIf="!addressForm.value.city2"
            class="add-company-prewiew-data__block__text-red"
          >
            {{'emty' | translate}}
          </p>
        </div>
        <div
          *ngIf="addressForm.value.real == '1'"
          class="add-company-prewiew-data__block"
        >
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.country' | translate}}
          </p>
          <p class="add-company-prewiew-data__block__text">
            {{getCountryName(addressForm.value.country2)}}
          </p>
        </div>
        <div
          *ngIf="addressForm.value.real == '1'"
          class="add-company-prewiew-data__block"
        >
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.city' | translate}}
          </p>
          <p
            *ngIf="addressForm.value.city2"
            class="add-company-prewiew-data__block__text"
          >
            {{getCityName(addressForm.value.city2)}}
          </p>
          <p
            *ngIf="!addressForm.value.city2"
            class="add-company-prewiew-data__block__text-red"
          >
            {{'emty' | translate}}
          </p>
        </div>
        <div
          *ngIf="addressForm.value.haveAnAddress"
          class="add-company-prewiew-data__block"
        >
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.variant' | translate}}
          </p>
          <p class="add-company-prewiew-data__block__text">
            {{'add-company-page.preview.iHaveAnAddress' | translate}}
          </p>
          <p class="add-company-prewiew-data__block__title">
            {{'add-company-page.preview.companyAddress' | translate}}
          </p>
          <p
            class="add-company-prewiew-data__block__text"
            *ngIf="addressForm.value.city"
          >
            {{getCountryName(addressForm.value.country)}}, {{addressForm.value.city}},
            {{addressForm.value.address1}}, {{addressForm.value.postalCode}}
          </p>
          <p
            *ngIf="!addressForm.value.city"
            class="add-company-prewiew-data__block__text-red"
          >
            {{'emty' | translate}}
          </p>
        </div>
      </div>
      <!--CONPANY ADDRESS-->
      <!--COMPANY VARINATS OF TITLE-->
      <div class="add-company-prewiew-data" [formGroup]="varinatsOfTitle">
        <button
          class="add-company-prewiew-data__edit-btn"
          (click)="editTitles()"
        >
          <img src="/assets/icons/pencil.svg" alt=""/>
        </button>
        <div class="add-company-prewiew-data__block">
          <div class="add-company-prewiew-name-container">
            <p class="add-company-prewiew-name-title">
              {{'add-company-page.preview.NAMES_VARIANTS' | translate}}
            </p>
            <div>
              <p class="add-company-prewiew-data__block__text">
                {{ varinatsOfTitle.value.variant1 || ''}}
              </p>

              <p class="add-company-prewiew-data__block__text">
                {{ varinatsOfTitle.value.variant2 || ''}}
              </p>

              <p class="add-company-prewiew-data__block__text">
                {{ varinatsOfTitle.value.variant3 || ''}}
              </p>
            </div>
          </div>


          <!-- <input
            class="add-company-prewiew-data__block__input"
            type="text"
            [placeholder]="structureForm.value.jurisdictionalForm"
            [formControlName]="'jurisdictionalForm'"
          /> -->
        </div>
      </div>
      <!--CONPANY VARINATS OF TITLE-->
    </div>
    <div class="add-company-prewiew__content__1" [formGroup]="directorsForm">
      <div
        class="add-company-prewiew__directors__director"
        *ngFor="let directorsGroup of directorsFormArray.controls; let i = index"
      >
        <div
          class="add-company-prewiew-director-personal"
          *ngIf="!directorsFormArray.value[i].rights"
        >
          <p class="add-company-prewiew__directors__director__title">
            {{"add-company-page.preview.director" | translate}} № {{i + 1}}
          </p>
          <div class="add-company-prewiew-data">
            <button
              class="add-company-prewiew-data__edit-btn"
              (click)="editDirectors()"
            >
              <img src="/assets/icons/pencil.svg" alt=""/>
            </button>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.nameAndLastName' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{directorsFormArray.value[i].nameAndLastName}}
              </p>
            </div>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.citizenship' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{directorsFormArray.value[i].citizenship}}
              </p>
            </div>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.passport' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{directorsFormArray.value[i].passport}}
              </p>
            </div>
            <div
              class="add-company-prewiew-data__block"
              *ngIf="directorsFormArray.value[i].founder"
            >
              <p
                class="add-company-prewiew-data__block__title"
                [style]="{color: 'green'}"
              >
                {{'add-company-page.preview.founder' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{directorsFormArray.value[i].founder ? ("yes" | translate) : ("no" | translate)}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="add-company-prewiew__directors__director"
        *ngFor="let foundersGroup of foundersFormArray.controls; let i = index"
      >
        <div
          class="add-company-prewiew-director-personal"
          *ngIf="!foundersFormArray.value[i].legal"
        >
          <p class="add-company-prewiew__directors__director__title">
            {{"add-company-page.preview.founder" | translate}} № {{i + 1}}
          </p>
          <div class="add-company-prewiew-data">
            <button
              class="add-company-prewiew-data__edit-btn"
              (click)="editFounders()"
            >
              <img src="/assets/icons/pencil.svg" alt=""/>
            </button>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.nameAndLastName' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{foundersFormArray.value[i].nameAndLastName}}
              </p>
            </div>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.citizenship' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{foundersFormArray.value[i].citizenship}}
              </p>
            </div>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.passport' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{foundersFormArray.value[i].passport}}
              </p>
            </div>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.proportion' | translate}} (%)
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{foundersFormArray.value[i].share}}
              </p>
            </div>
          </div>
        </div>
        <div
          class="add-company-prewiew-director-personal"
          *ngIf="foundersFormArray.value[i].legal"
        >
          <p class="add-company-prewiew__directors__director__title">
            {{"add-company-page.preview.founder" | translate}} № {{i + 1}}
          </p>
          <div class="add-company-prewiew-data">
            <button
              class="add-company-prewiew-data__edit-btn"
              (click)="editFounders()"
            >
              <img src="/assets/icons/pencil.svg" alt=""/>
            </button>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.name' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{foundersFormArray.value[i].name}}
              </p>
            </div>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.country' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{foundersFormArray.value[i].country}}
              </p>
            </div>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.regNumber' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{foundersFormArray.value[i].regNumber}}
              </p>
            </div>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.form' | translate}}
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{foundersFormArray.value[i].form}}
              </p>
            </div>
            <div class="add-company-prewiew-data__block">
              <p class="add-company-prewiew-data__block__title">
                {{'add-company-page.preview.proportion' | translate}} (%)
              </p>
              <p class="add-company-prewiew-data__block__text">
                {{foundersFormArray.value[i].share}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="add-company-prewiew-bottom">
    <div class="add-company-prewiew-bottom__left">
      <div class="add-company-prewiew-bottom-price">
        <p class="add-company-prewiew-bottom-price__text">
          {{"add-company-page.preview.regCost" | translate}}
        </p>
        <p class="add-company-prewiew-bottom-price__price">
          {{currency}} {{regPrice}}
        </p>
      </div>

      <div
        class="add-company-prewiew-bottom-price"
        *ngIf="addressForm.value.real == '1'"
      >
        <p class="add-company-prewiew-bottom-price__text">
          {{"add-company-page.preview.virtualCost" | translate}}
        </p>
        <p class="add-company-prewiew-bottom-price__price">
          {{currency}} {{isRenew}}
        </p>
      </div>
      <div
        class="add-company-prewiew-bottom-price"
        *ngIf="addressForm.value.real == '2'"
      >
        <p
          class="add-company-prewiew-bottom-price__text"
          style="text-transform: uppercase"
        >
          {{"add-company-page.preview.realOfficeCost" | translate}}
        </p>
        <p class="add-company-prewiew-bottom-price__big-price">€ 499</p>
      </div>
      <div class="add-company-prewiew-bottom-price">
        <p
          class="add-company-prewiew-bottom-price__text"
          style="text-transform: uppercase"
        >
          {{"add-company-page.preview.price" | translate}}
        </p>
        <p class="add-company-prewiew-bottom-price__big-price">
          {{currency}} {{price}}
        </p>
      </div>

      <div
        class="add-company-prewiew-bottom-price-end"
        *ngIf="addressForm.value.real == '1'"
      >
        <div class="add-company-prewiew-bottom-price__texts">
          <p
            class="add-company-prewiew-bottom-price__text"
            style="color: #aaadba"
          >
            {{"add-company-page.preview.costOfContinue" | translate}}
          </p>
          <p class="add-company-prewiew-bottom-price__text">
            {{"add-company-page.preview.virtualCost" | translate}}
          </p>
        </div>
        <p
          class="add-company-prewiew-bottom-price__price"
          style="color: #a06af9"
        >
          {{currency}} {{isRenew}}
        </p>
      </div>
    </div>
    <div class="add-company-prewiew-bottom__right">
      <button
        class="Button-blue add-company-page__submit-nonActive"
        [ngClass]="setContinueBtn ? 'add-company-page__submit' : ''"
        (click)="submitStructure()"
      >
        {{'add-company-page.preview.pay' | translate}}
      </button>
    </div>
  </div>
</div>

<app-modal-window
  [open]="showModal"
  [title]="'alerts.paymentSuccess' | translate"
  [okText]="'alerts.ok-btn' | translate"
  [okFunction]="closeModal"
></app-modal-window>

<app-modal-window
  [open]="showInvoiceModal"
  [title]="'alerts.invoiceModalText' | translate"
  [okText]="'alerts.ok-btn' | translate"
  [okFunction]="closeInvoiceModal"
></app-modal-window>

<app-modal-window
  [open]="showPaymentCardsModal"
  [title]="'alerts.selectPaymentCard' | translate"
  [okText]="'alerts.ok-btn' | translate"
  [okFunction]="navigateToCards"
></app-modal-window>

<app-modal-window
  *ngIf="is3dsPayment"
  [open]="is3dsPayment"
  [isMessage]="false"
  [formHex]="formHexCode"
></app-modal-window>

<app-modal-window
  [open]="_3dsPaymentSuccessful"
  [title]="'alerts.paymentSuccess' | translate"
  [okText]="'alerts.ok-btn' | translate"
  [okFunction]="closeModal"
></app-modal-window>

<app-modal-window
  [open]="_3dsPaymentFailure"
  [title]="'alerts.paymentFailure' | translate"
  [okText]="'alerts.ok-btn' | translate"
  [okFunction]="closeModalWithoutRedirect"
></app-modal-window>
