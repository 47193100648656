<div *ngIf="company" class="put-company-on-sale-page">
	<ng-container *ngIf="step === 1">
		<div class="title">
			The value of my company
		</div>

		<div class="price-container">
			<div class="price">{{ price }}</div>
			<div class="currency">{{ company.currency }}</div>
		</div>

		<div class="slider-container">
			<input
	      type="range"
	      class="select-scroller__input"
	      [min]="10000"
	      [max]="100000"
	      [step]="100"
	      [(ngModel)]="price"
	    />

	    <div class="period-container">
	    	<div class="period-text">6 months</div>
	    	<div class="period-text">2 weeks</div>
	    	<div class="period-text">1 week</div>
	    	<div class="period-text">5 days</div>
	    </div>
		</div>

		<div class="description">
			Какой то текст который описывает зависимость время продажи от выставленой цены Какой то текст который описывает зависимость время продажи от выставленой ценыКакой то текст который описывает зависимость время продажи от выставленой цены
		</div>

		<button class="sale-button" (click)="onNextStepClicked()">List a company</button>
	</ng-container>

	<ng-container *ngIf="step === 2">
		<div class="title">
			Offer agreement
		</div>

		<div class="offer-container">
			<div class="bold">Allgemeine Geschäftsbedingungen für den Verkauf von fertigen Unternehmen und die Gründung neuer Unternehmen (UG, GmbH)</div>
			<div>Verö&entlichungsdatum des Angebots: 01.11.2023</div>
			<div>Dieser Vertrag stellt ein o&izielles Angebot der Firma Firmader GmbH (im Folgenden “Anbieter”) an natürliche und juristische Personen (im Folgenden “Kunde”) dar, einen Vertrag über die Bereitstellung von Dienstleistungen zum Verkauf von fertigen Unternehmen und die Gründung neuer Unternehmen (UG, GmbH) in Deutschland gemäß den deutschen Gesetzen abzuschließen.</div>
			<div class="bold">1. Allgemeine Bestimmungen</div>
			<div>1.1. Dieser Vertrag regelt die Beziehungen zwischen dem Anbieter und dem Kunden, die im Zusammenhang mit der Bereitstellung von Dienstleistungen über den Internet-Service unter der Adresse: firmder.com (im Folgenden “Service”) entstehen.</div>
			<div>1.2. Die Annahme dieses Angebots erfolgt durch die Registrierung des Kunden im Service und/oder die Nutzung der Funktionalitäten des Services.</div>
			<div class="bold">2. Vertragsgegenstand</div>
			<div>2.1. Der Anbieter verpflichtet sich, dem Kunden Dienstleistungen zum Verkauf von fertigenUnternehmen und Geschäften sowie Dienstleistungen zur Gründung neuer Unternehmen (UG, GmbH) in Deutschland zu erbringen. Der Kunde verpflichtet sich, diese Dienstleistungen gemäß den Bedingungen dieses Vertrags anzunehmen und zu bezahlen.</div>
			<div class="bold">3. Rechte und Pflichten der Parteien</div>
			<div class="bold">3.1. Pflichten des Anbieters:</div>
			<div>3.1.1. Bereitstellung des Zugangs zum Service nach der Registrierung des Kunden.</div>
			<div>3.1.2. Gewährleistung der Möglichkeit, Angebote zum Verkauf fertiger Unternehmen zu platzieren und zu suchen.</div>
			<div>3.1.3. Bereitstellung von Beratung und Unterstützung bei Fragen zur Nutzung des Services.</div>
			<div class="bold">3.2. Rechte des Anbieters:</div>
			<div>3.2.1. Anforderung notwendiger Dokumente vom Kunden zur Erbringung der Dienstleistungen.</div>
			<div>3.2.2. Ablehnung der Dienstleistungen bei Verstoß des Kunden gegen die Bedingungen dieses Vertrags oder gegen die Gesetze Deutschlands.</div>
			<div class="bold">3.3. Pflichten des Kunden:</div>
			<div>3.3.1. Bereitstellung wahrheitsgemäßer Daten bei der Registrierung und Nutzung des Services.</div>
			<div>3.3.2. Einhaltung der Bedingungen dieses Vertrags und der Gesetze Deutschlands.</div>
			<div>3.3.3. Bezahlung der Dienstleistungen des Anbieters gemäß den festgelegten Verfahren.</div>
			<div class="bold">3.4. Rechte des Kunden:</div>
			<div>3.4.1. Nutzung der Funktionalitäten des Services gemäß seinem Zweck.</div>
			<div>3.4.2. Erhalt von Informationen und Beratungen vom Anbieter zu Fragen der Service-Nutzung.</div>
			<div class="bold">4. Bereitstellungsverfahren der Dienstleistungen</div>
			<div>4.1. Der Kunde wählt die erforderlichen Dienstleistungen im Service aus und erstellt eine Anfrage über die entsprechenden Funktionen des Services.</div>
			<div>4.2. Nach Erstellung der Anfrage zahlt der Kunde die Dienstleistungen gemäß den in Abschnitt 5 dieses Vertrags festgelegten Verfahren.</div>
			<div>4.3. Der Anbieter beginnt mit der Erbringung der Dienstleistungen nach Bestätigung der Zahlung</div>
			<div class="bold">5. Kosten der Dienstleistungen und Zahlungsbedingungen</div>
			<div>5.1. Die Kosten der Dienstleistungen werden gemäß den zum Zeitpunkt der Anfrage auf dem Service angegebenen Tarifen festgelegt.</div>
			<div>5.2. Die Zahlung erfolgt in Euro über die auf dem Service verfügbaren Zahlungsmethoden.</div>
			<div>5.3. Die Zahlungspflicht des Kunden gilt als erfüllt, sobald die Gelder auf dem Konto des Anbieters gutgeschrieben sind.</div>
			<div class="bold">6. Haftung der Parteien</div>
			<div>6.1. Bei Nichterfüllung oder unsachgemäßer Erfüllung der Verpflichtungen aus diesem Vertrag haften die Parteien gemäß den Gesetzen Deutschlands.</div>
			<div>6.2. Der Anbieter haftet nicht für Handlungen Dritter sowie für mögliche Verluste des Kunden, die sich aus der Nutzung des Services ergeben.</div>
			<div class="bold">7. Vertraulichkeit und Datenschutz</div>
			<div>7.1. Die Parteien verpflichten sich, die Vertraulichkeit der im Rahmen dieses Vertrags erhaltenen Informationen zu wahren.</div>
			<div>7.2. Die Verarbeitung personenbezogener Daten des Kunden erfolgt gemäß der Datenschutzrichtlinie des Anbieters und den Datenschutzgesetzen Deutschlands.</div>
			<div class="bold">8. Streitbeilegung</div>
			<div>8.1. Streitigkeiten und Meinungsverschiedenheiten, die sich aus diesem Vertrag ergeben, werden durch Verhandlungen zwischen den Parteien gelöst.</div>
			<div>8.2. Kann der Streit nicht durch Verhandlungen beigelegt werden, wird er vor dem zuständigen Gericht am Sitz des Anbieters verhandelt.</div>
			<div class="bold">9. Vertragslaufzeit und Änderungen der Vertragsbedingungen</div>
			<div>9.1. Dieser Vertrag tritt mit der Annahme des Angebots durch den Kunden in Kraft und gilt bis zur vollständigen Erfüllung der Verpflichtungen durch beide Parteien.</div>
			<div>9.2. Der Anbieter hat das Recht, die Bedingungen dieses Vertrags einseitig zu ändern. Änderungen treten mit ihrer Verö&entlichung auf dem Service in Kraft.</div>
			<div class="bold">10. Sonstige Bestimmungen</div>
			<div>10.1. Die Ungültigkeit einzelner Bestimmungen dieses Vertrags berührt nicht die Gültigkeit des gesamten Vertrags.</div>
			<div>10.2. In allen nicht durch diesen Vertrag geregelten Punkten richten sich die Parteien nach den Gesetzen Deutschlands.</div>
			<div class="bold">11. Angaben des Anbieters</div>
			<div>FIRMDER UG (haftungsbeschränkt)</div>
			<div>Adresse: Unter den Linden 24, 10117, Berlin</div>
			<div>E-Mail: info@firmder.com</div>
			<div class="bold">12. Bestätigung der Annahme</div>
			<div>Die weitere Nutzung des Services nach der Verö&entlichung dieses Angebots bedeutet die vollständige und bedingungslose Annahme der Bedingungen dieses Vertrags durch den Kunden.</div>
		</div>

		<div class="checkbox-container">
			<input type="checkbox" [(ngModel)]="checkbox"/>
			<div class="">I accept the terms of the offer</div>
		</div>

		<button 
			class="sale-button" 
			(click)="onPutOnSaleClicked()"
			[disabled]="!checkbox"
		>List a company</button>
	</ng-container>
</div>