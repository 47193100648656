import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subscription } from 'rxjs';

import {
  getCompanyById,
  getCompanyByIdSuccess,
  getCompanyByIdFailure,
} from 'app/store/Shared/shared.actions';

@Component({
    selector: 'CompanyPricePage',
    templateUrl: './ui/CompanyPricePage.component.html',
    styleUrls: ['./ui/CompanyPricePage.component.scss'],
})
export class CompanyPricePage implements OnInit {
  subs: Subscription[] = [];
  company: any;
  price: any;
  id: string | null = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private actions: Actions,
    private store: Store<any>,
  ) {}

  ngOnInit() {
    this._getCompanyInfo();
    this._getCompanyListener();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getCompanyInfo() {
    this.id =
      this.route && this.route.snapshot && this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(getCompanyById({
      id: this.id
    }));
  }

  private _getCompanyListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdSuccess.type))
        .subscribe(({ data }: any) => {
          if (data) {
            if (data.state !== 3) {
              // this.router.navigate([`/account/my-orders/`]);
            }
            this.company = data;
          }
        })
    );

    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdFailure.type))
        .subscribe(({ data }: any) => {
          this.router.navigate([`/account/my-orders/`]);
        })
    );
  }

  public getWidth() {
    let width = 0;
    this.company.companyFeatures.map((cf: any) => {
      width += cf.percent;
    })
    return 754 - 754 / 100 * width;
  }

  public onUrlClick(url: string) {
    console.log(url);
  }

  public onLickCompanyClick(id: string) {
    this.router.navigate([`/account/put-company-for-sale/${id}`]);
  }
}


