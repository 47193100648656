import { Component, Input } from '@angular/core';
import { AppState } from 'app/store/app.state';
import { Store } from '@ngrx/store';
import { sendFile } from 'app/store/Shared/shared.actions';
import { setRequestError } from 'app/store/Shared/shared.actions';

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}
@Component({
  selector: 'app-circle-img',
  templateUrl: './ui/circle-img.component.html',
  styleUrls: ['./ui/circle-img.component.scss'],
})
export class CircleImgComponent {
  @Input() pic: string = '';
  @Input() size: string = '35px';
  @Input() sizeImg: string = '';
  @Input() upload: boolean = false;
  @Input() imgId: string = '';
  images: any;

  constructor(private store: Store<AppState>) {}
  selectImage(event: Event) {
    event.preventDefault();
    const files = (event.target as HTMLInputElement).files;
    if (files && files.length > 0) {
      this.images = files[0];
      if (this.images.size > 15 * 1024 * 1024) {
        this.store.dispatch(setRequestError({ message: 'size is too big, 15MB max' }));
      } else {
        let formData = new FormData();
        formData.append("file", files[0], files[0].name);
        formData.append("path", "avatars");
        this.store.dispatch(sendFile({data: formData}));
      }

    }
  }
  submit() {
    if (this.images) {
    }
  }
}
