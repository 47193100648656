<form
  *ngIf="!loading"
  class="add-company"
  [formGroup]="companyForm"
  [style]="{
    width: step == 6 ? '100%' : '',
    height: step == 0 ? 'fit-content' : ''
  }"
>
  <app-add-company-jurisdiction
    *ngIf="step == 0"
    [formGroupName]="'address'"
    [nextStep]="nextStep"
    [previewStep]="previewStep"
  >
    <ng-container loading>
      <div class="loading-pink">
        <div
          class="loading-pink__bar"
          [style]="{ width: step * 20 + '%' }"
        ></div>
      </div>
    </ng-container>
    <ng-container price>
      <div class="add-company__price" *ngIf="price">
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text">
          {{ companyOption.value?.currency }} {{ price }}
        </p>
      </div>
      <div
        *ngIf="renewPrice"
        class="add-company__price"
        style="margin-top: 2px"
      >
        <p class="add-company__price__label">
          {{ "add-company-page.prewiew.costOfContinue" | translate }}
        </p>
        <p class="add-company__price__text-pink">
          {{ companyOption.value?.currency }} {{ renewPrice }}
        </p>
      </div>
    </ng-container>
  </app-add-company-jurisdiction>
  <app-add-company-structure
    formGroupName="structure"
    [setStructureJurisdictionalForm]="setStructureJurisdictionalForm"
    [numberOfDirectors]="numberOfDirectors"
    [numberOfFounders]="numberOfFounders"
    [nextStep]="nextStep"
    [previewStep]="previewStep"
    [toSpecificStep]="toSpecificStep"
    *ngIf="step == 1"
    [prevStep]="prevStep"
    [selectedCountryId]="selectedCountryId"
  >
    <ng-container loading>
      <div class="loading-pink">
        <div
          class="loading-pink__bar"
          [style]="{ width: step * 20 + '%' }"
        ></div>
      </div>
    </ng-container>
    <ng-container price>
      <div class="add-company__price">
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text">
          {{ companyOption.value?.currency }} {{ price }}
        </p>
      </div>
      <div
        *ngIf="renewPrice"
        class="add-company__price"
        style="margin-top: 2px"
      >
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text-pink">
          {{ companyOption.value?.currency }} {{ renewPrice }}
        </p>
      </div>
    </ng-container>
  </app-add-company-structure>
  <app-add-company-address
    *ngIf="step == 2"
    [formGroupName]="'address'"
    [nextStep]="nextStep"
    [prevStep]="prevStep"
    [previewStep]="previewStep"
  >
    <ng-container loading>
      <div class="loading-pink">
        <div
          class="loading-pink__bar"
          [style]="{ width: step * 20 + '%' }"
        ></div>
      </div>
    </ng-container>
    <ng-container price>
      <div class="add-company__price">
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text">
          {{ companyOption.value?.currency }} {{ price }}
        </p>
      </div>
      <div
        *ngIf="renewPrice"
        class="add-company__price"
        style="margin-top: 2px"
      >
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text-pink">
          {{ companyOption.value?.currency }} {{ renewPrice }}
        </p>
      </div>
    </ng-container>
  </app-add-company-address>
  <app-add-company-title-variants
    *ngIf="step == 3"
    [formGroupName]="'titleVariants'"
    [nextStep]="nextStep"
    [prevStep]="prevStep"
    [previewStep]="previewStep"
    [suffixes]="titlePrefixes"
  >
    <ng-container loading>
      <div class="loading-pink">
        <div
          class="loading-pink__bar"
          [style]="{ width: step * 20 + '%' }"
        ></div>
      </div>
    </ng-container>
    <ng-container price>
      <div class="add-company__price">
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text">
          {{ companyOption.value?.currency }} {{ price }}
        </p>
      </div>
      <div
        *ngIf="renewPrice"
        class="add-company__price"
        style="margin-top: 2px"
      >
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text-pink">
          {{ companyOption.value?.currency }} {{ renewPrice }}
        </p>
      </div>
    </ng-container>
  </app-add-company-title-variants>
  <app-add-company-participants
    *ngIf="step == 4"
    [bigFormGroupName]="'directorsGroup'"
    [nextStep]="nextStep"
    [structureFormGroupName]="'structure'"
    [prevStep]="prevStep"
    [addDirector]="addDirectorFormGroup"
    [previewStep]="previewStep"
  >
    <ng-container loading>
      <div class="loading-pink">
        <div
          class="loading-pink__bar"
          [style]="{ width: step * 20 + '%' }"
        ></div>
      </div>
    </ng-container>
    <ng-container price>
      <div class="add-company__price">
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text">
          {{ companyOption.value?.currency }} {{ price }}
        </p>
      </div>
      <div
        *ngIf="renewPrice"
        class="add-company__price"
        style="margin-top: 2px"
      >
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text-pink">
          {{ companyOption.value?.currency }} {{ renewPrice }}
        </p>
      </div>
    </ng-container>
  </app-add-company-participants>
  <app-add-company-founders
    *ngIf="step == 5"
    [formGroup2Name]="'individual'"
    [structureFormGroupName]="'structure'"
    [bigFormGroupName]="'foundersGroup'"
    [nextStep]="nextStep"
    [prevStep]="prevStep"
    [addFounder]="addFoundersFormGroup"
    [addIndividualFounder]="createFounderIndividualFormGroup"
    [previewStep]="previewStep"
  >
    <ng-container loading>
      <div class="loading-pink">
        <div
          class="loading-pink__bar"
          [style]="{ width: step * 20 + '%' }"
        ></div>
      </div>
    </ng-container>
    <ng-container price>
      <div class="add-company__price">
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text">
          {{ companyOption.value?.currency }} {{ price }}
        </p>
      </div>
      <div
        *ngIf="renewPrice"
        class="add-company__price"
        style="margin-top: 2px"
      >
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text-pink">
          {{ companyOption.value?.currency }} {{ renewPrice }}
        </p>
      </div>
    </ng-container>
  </app-add-company-founders>
  <app-add-company-prewiew
    *ngIf="step == 6"
    [nextStep]="nextStep"
    [prevStep]="prevStep"
    [addressFormName]="'address'"
    [structureFormName]="'structure'"
    [directorsFormName]="'directorsGroup'"
    [foundersFormName]="'foundersGroup'"
    [varinatsOfTitleFormName]="'titleVariants'"
    [price]="price"
    [isRenew]="renewPrice"
    [regPrice]="regPrice"
    [regPrice]="regPrice"
    [currency]="currency"
  >
    <ng-container loading>
      <div class="loading-pink">
        <div
          class="loading-pink__bar"
          [style]="{ width: step * 20 + '%' }"
        ></div>
      </div>
    </ng-container>
    <ng-container price>
      <div class="add-company__price">
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text">€ {{ "1499" }}</p>
      </div>
      <div
        *ngIf="
          this.companyForm.controls.address.value.real == false &&
          this.companyForm.controls.address.value.real !== ''
        "
        class="add-company__price"
        style="margin-top: 2px"
      >
        <p class="add-company__price__label">
          {{ "add-company-page.structure.costOfRegistration" | translate }}
        </p>
        <p class="add-company__price__text-pink">€ {{ "499" }}</p>
      </div>
    </ng-container>
  </app-add-company-prewiew>
</form>
