<div
  class="add-company-participants"
  style="max-width: 704px"
  [formGroup]="participantsForm"
>
  <div class="add-company-title-container">
    <div
      class="add-company-title-container__nav"
      *ngIf="editable !== 'directors'"
    >
      <button class="add-company-title-container__back" (click)="prevStep()">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
      <button class="add-company-title-container__next" (click)="nextStep()" [disabled]="!continueButton">
        <img src="assets/icons/arrow-left.svg" alt="arrow-left-button" />
      </button>
    </div>
    <h2 class="add-company-title">
      {{ "add-company-page.participants.title.1" | translate }}
      <br />
      {{ "add-company-page.participants.title.2" | translate }}
    </h2>
  </div>

  <div class="add-company-step">
    <div class="add-company-step__text">4/5</div>
  </div>
  
  <ng-content select="[loading]"></ng-content>
  <div class="add-company-participants__content" formArrayName="directors">
    <div
      class="add-company-participants__content__director"
      *ngFor="let group of numberOfDirectors; let i = index"
      [formGroupName]="i"
    >
      <p class="add-company-participants__content__director__label">
        {{'add-company-page.participants.director' | translate}} № {{i + 1}}
      </p>
      <!-- <button
        class="add-company-participants__content__director__rights-btn"
        (click)="setLegalEntity(i)"
      >
        {{directorsFormArray.value[i].rights ?
        ('add-company-page.participants.individualEntity' | translate) : (
        'add-company-page.participants.legalEntity' | translate)}}
      </button> -->
      <div class="add-company-participants__content__director__inputs">
        <div class="add-company-participants__input">
          <label class=""
            >{{'add-company-page.participants.firstAndLastName'|
            translate}}</label
          >
          <input type="text" formControlName="nameAndLastName" />
        </div>

        <div class="add-company-participants__input">
          <label class=""
            >{{'add-company-page.participants.citizenship'| translate}}</label
          >
          <input type="text" formControlName="citizenship" />
          <!--<app-dropdown-type-1
            [name]="
            directorsFormArray.value[i].citizenship
              ? getCountryName(directorsFormArray.value[i].citizenship)
              : ('add-company-page.address.country' | translate)
          "
            [nameDefault]="'add-company-page.address.country' | translate"
            [elements]="countries"
            [size]="'100%'"
            [color]="'#262A34'"
            (setItemFromButton)="setDirectorsCountry(i, $event)"
            (settingDropdownOpen)="setDropdown($event)"
            [dropdownOpen]="dropdown == i + 1 ? true : false"
            [dropdown]="(i + 1).toString()"
          />-->
        </div>
        <div class="add-company-participants__input">
          <label class=""
            >{{'add-company-page.participants.Passport'| translate}}</label
          >
          <input type="text" formControlName="passport" />
        </div>
        <!-- <div class="add-company-participants__input-share">
          <label class="">{{'shares'| translate}}</label>
          <div class="add-company-participants__input-share__content">
            <input type="number" formControlName="share" />
            <p class="add-company-participants__input-share__content__text">
              %
            </p>
          </div>
        </div> -->
      </div>
      <div class="turn-input">
        <p class="turn-input__text">
          {{'add-company-page.participants.directorIsFounder' | translate}}
        </p>
        <div class="swicher" [ngClass]="{'disabled-div': disableIsFounder[i]}">
          <input type="checkbox" formControlName="founder" ng-disabled="disableIsFounder[i]" />
          <span class="swicher__slider swicher__round"></span>
        </div>
      </div>
      <!-- <div *ngIf="shares >= 0" class="shares">
        <p class="shares__text">{{"add-company-page.availableshares" | translate}}</p>
        <p class="shares__content">{{shares}}%</p>
      </div>
      <div *ngIf="shares < 0" class="shares">
        <p style="color: red !important" class="shares__text">
          {{"add-company-page.impropershare" | translate}}
        </p>
      </div> -->
      <!-- <button
        (click)="deleteDirector(i)"
        class="add-company-participants__dell-director-button"
      >
        {{'add-company-page.participants.delDirector' | translate}}
      </button> -->
    </div>
  </div>
  <!-- <button
    (click)="addDirector()"
    class="add-company-participants__add-director-button"
  >
    {{'add-company-page.participants.addDirector' | translate}}
  </button> -->

  <ng-content select="[price]"></ng-content>
  <button
    class="Button-blue add-company-page__submit-nonActive"
    [ngClass]="continueButton ? 'add-company-page__submit' : ''"
    (click)="submitStructure()"
    [disabled]="!continueButton"
  >
    {{'continue-btn' | translate}}
  </button>
</div>

<button
  type="button"
  class="save-template-button"
  [ngClass]="!continueButton ? 'nonActive' : ''"
  (click)="submitStructure()"
  [disabled]="!continueButton"
>{{ "add-company-page.saveToDrafts" | translate }}</button>
