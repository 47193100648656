import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { environment } from '@environment/environment';
import { SharedService } from 'app/store/Shared/services/SharedServices';

import {
  getCompanyById,
  getCompanyByIdSuccess,
  getCompanyByIdFailure,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-marketplace-view',
  templateUrl: './ui/marketplace-view.component.html',
  styleUrls: ['./ui/marketplace-view.component.scss'],
})
export class MarketplaceView implements OnInit {
  subs: Subscription[] = [];
  company: any;

  constructor(
    // private sharedService: SharedService,
    private store: Store<any>,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    let id =
      this.route && this.route.snapshot && this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(getCompanyById({
      id: id
    }));

    this._getCompanyListener();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getCompanyListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdSuccess.type))
        .subscribe(({ data }: any) => {
          if (data) {
            this.company = data;
          }
        })
    );

    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdFailure.type))
        .subscribe(({ data }: any) => {
          this.router.navigate([`/account/marketplace/`]);
        })
    );
  }

  public getPrefix(prefix: string) {
    return prefix.split(' ')[0] || '';
  }

  public getRegistrationDate() {
    return this.company.createdAt.split('-')[0] || '-';
  }

  public onBuyClick() {
    this.router.navigate([`/account/marketplace/${this.company.id}/offer`]);
  }

  public booleanToYesNo(value: boolean) {
    return value ? "Yes" : "No";
  }
}
