<div *ngIf="company" class="company-price-page">
	<div class="title">
		The value of my company
	</div>


	<div class="inside-circle">
		<div class="price-container">
			<div class="price">{{ company.priceForSale }}</div>
			<div class="currency">{{ company.currency }}</div>
		</div>
		<button (click)="onLickCompanyClick(company.id)">List company</button>
	</div>

	<div class="circle-container">
	  <svg width="250" height="250">
		  <g transform="rotate(-90 100 100)">
		    <circle r="120" cx="75" cy="125" fill="transparent" stroke="#2B2134" stroke-width="6px" stroke-dasharray="754" stroke-dashoffset="0"></circle>
		    <circle r="120" cx="75" cy="125" fill="transparent" stroke="#D3EA47" stroke-width="6px" stroke-dasharray="754" [attr.stroke-dashoffset]="getWidth()"></circle>
		  </g>
		</svg>
	</div>

	<div class="description">
		Узнайте средний срок <a href="javascript:void(0)">продажи похожих компаний</a>
	</div>

	<div class="features-container">
		<div class="title">What influences the cost</div>
		<div *ngFor="let feature of company.companyFeatures" class="feature-row">
			<div class="feature-container">
				<div class="percent">+{{feature.percent}}%</div>
				<div class="label">{{feature.name}}</div>
			</div>
			<button *ngIf="feature.url" class="button" (click)="onUrlClick(feature.url)">button_name</button>
		</div>
	</div>
</div>