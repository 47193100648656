import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { environment } from '@environment/environment';

import {
  getCompanyById,
  getCompanyByIdSuccess,
  getCompanyByIdFailure,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-marketplace-documents',
  templateUrl: './ui/marketplace-documents.component.html',
  styleUrls: ['./ui/marketplace-documents.component.scss'],
})
export class MarketplaceDocuments implements OnInit {
  subs: Subscription[] = [];
  id: string | null = null;
  company: any;
  userId: string | null = null;
  buyer: boolean = false;

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.id =
      this.route && this.route.snapshot && this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(getCompanyById({
      id: this.id
    }));
    this._getUserInfo();
    this._getCompanyListener();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getUserInfo() {
    let userInfo = localStorage.getItem('firmderWebUser');
    if (userInfo) {
      this.userId = JSON.parse(userInfo).user.id;
    } else {
      this.router.navigate([`/account/marketplace/${this.id}`]);
    }
  }

  private _getCompanyListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdSuccess.type))
        .subscribe(({ data }: any) => {
          if (data && data.buyersOfferAgreements) {
            if (data.buyersOfferAgreements.findIndex((e: any) => e === this.userId) !== -1) {
              this.company = data;
              this.buyer = data.buyerUserId === this.userId; 
            } else {
              this.router.navigate([`/account/marketplace/${this.id}`]);
            }
          } else {
            this.router.navigate([`/account/marketplace/`]);
          }
        })
    );

    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdFailure.type))
        .subscribe(({ data }: any) => {
          this.router.navigate([`/account/marketplace/`]);
        })
    );
  }

  public getPrefix(prefix: string) {
    return prefix.split(' ')[0] || '';
  }

  public getRegistrationDate() {
    return this.company.createdAt.split('-')[0] || '-';
  }

  public onBuyClick() {
    this.router.navigate([`/account/marketplace/${this.company.id}/comission`]);
  }

  public booleanToYesNo(value: boolean) {
    return value ? "Yes" : "No";
  }

  public openLink(url: string) {
    window.open(url, "_blank");
  }
}
