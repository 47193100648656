import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { environment } from '@environment/environment';
import { SharedService } from 'app/store/Shared/services/SharedServices';

import {
  getCompanyById,
  getCompanyByIdSuccess,
  getCompanyByIdFailure,
  buyerOfferAgreement,
  buyerOfferAgreementSuccess,
} from 'app/store/Shared/shared.actions';

@Component({
  selector: 'app-marketplace-offer',
  templateUrl: './ui/marketplace-offer.component.html',
  styleUrls: ['./ui/marketplace-offer.component.scss'],
})
export class MarketplaceOffer implements OnInit {
  subs: Subscription[] = [];
  checkbox: boolean = false;
  id: string | null = null;

  constructor(
    private store: Store<any>,
    private actions: Actions,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this._getCompanyInfo();
    this._getCompanyListener();
    this._agreementListener();
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  private _getCompanyInfo() {
    this.id =
      this.route && this.route.snapshot && this.route.snapshot.paramMap.get('id') || '';
    this.store.dispatch(getCompanyById({
      id: this.id
    }));
  }

  private _getCompanyListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdSuccess.type))
        .subscribe(({ data }: any) => {
          if (!(data && data.state === 4)) {
            this.router.navigate([`/account/marketplace/`]);
          }
        })
    );

    this.subs.push(
      this.actions
        .pipe(ofType(getCompanyByIdFailure.type))
        .subscribe(({ data }: any) => {
          this.router.navigate([`/account/marketplace/`]);
        })
    );
  }

  private _agreementListener() {
    this.subs.push(
      this.actions
        .pipe(ofType(buyerOfferAgreementSuccess.type))
        .subscribe(({ data }: any) => {
          this.router.navigate([`/account/marketplace/${this.id}/documents`]);
        })
    );
  }

  public onPayButtonClicked() {
    this.store.dispatch(buyerOfferAgreement({
      data: {
        id: this.id,
      }
    }));
  }
}
