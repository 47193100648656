import { Component, Input, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { companyOption } from 'app/store/Shared/model/SharedModel';
import { UserInfoProps } from 'app/store/UserInfo/models/userInfo.model';
import { AppState } from 'app/store/app.state';
import { BehaviorSubject } from 'rxjs';
import { DropdownType1Eement } from 'shared/ui/dropdown-type-1/circle-img/dropdown-type-1';
import {
  createTemplate,
  createTemplateSuccess,
  updateTemplate,
  updateTemplateSuccess,
} from 'app/store/Shared/shared.actions';
import { Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: 'app-add-company-structure',
  templateUrl: './ui/AddCompanyStructure.html',
  styleUrls: ['./ui/AddCompanyStructure.scss'],
})
export class AddCompanyStructure {
  @Input() formGroupName: string;
  userInfo: UserInfoProps | null;
  name: string = '';
  structureForm: FormGroup;
  addressForm: FormGroup;
  companyId: string | null = null;

  subs: Subscription[] = [];

  @Input() setStructureJurisdictionalForm: any;

  @Input() numberOfDirectors: string | number;
  @Input() numberOfFounders: string | number;

  @Input() nextStep: any;
  @Input() prevStep: any;
  @Input() previewStep: any;
  @Input() toSpecificStep: any;
  @Input() selectedCountryId: string;

  registrationOptionId: string | any = '';
  legalFormId: string | any = '';
  dropdown: string = '0';
  editable: string;
  foundersChanged: boolean = false;
  editable2: boolean = true;
  companyOptions: companyOption[];
  public companyOption = new BehaviorSubject<companyOption | undefined>(
    undefined
  );

  jurisdictionalForms: DropdownType1Eement[] = [];
  jurisdictionalInfoLink: string = '';

  // { title: 'Company' },
  // { title: 'LIMITED' },
  jurisdictionalForm = new BehaviorSubject<any>('');

  //continue button
  continueButton: boolean = false;

  capitalValues: any = null;

  @ViewChild('capital') capital: ElementRef;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private rootFormGroup: FormGroupDirective,
    private actions: Actions,
  ) {}

  ngOnInit() {
    //initialization of root form group
    this.structureForm = this.rootFormGroup.control.get(
      this.formGroupName
    ) as FormGroup;
    this.addressForm = this.rootFormGroup.control.get('address') as FormGroup;
    this.setContinueBtn();

    this._listenForCreateSuccess();
    this._listenForUpdateSuccess();

    //sets user info
    this.subs.push(
      this.store
        .select((state) => state.userinfo)
        .subscribe((info) => {
          this.userInfo = info.UserInfoData;
        })
    )

    //sets user name
    this.subs.push(
      this.store
        .select((state) => state.userinfo)
        .subscribe((state) => {
          // if (state.UserInfoData) {
          //   this.name = `${state.UserInfoData.firstName} ${state.UserInfoData.lastName}`;
          // }
        })
    )

    let keys = Object.keys(this.structureForm.value);
    let num = 0;
    keys.map((key) => {
      this.structureForm.value[key] !== '' ? '' : num++;
    });

    num > 0 ? (this.editable2 = false) : (this.editable2 = true);

    //editable
    this.subs.push(
      this.store
        .select((state) => state.shared.editableCompanyStep)
        .subscribe((editable) => {
          if (editable) {
            this.editable = editable;
          }
        })
    )

    //getting options state
    this.subs.push(
      this.store
        .select((state) => state.shared.companyOptions)
        .subscribe((options) => {
          if (options) {
            this.companyOptions = options;
            this.jurisdictionalInfoLink = options[0].jurisdictionInfoLink;
          }
        })
    )

    //getting options state
    this.subs.push(
      this.store
        .select((state) => state.shared.companyOptions)
        .subscribe((options) => {
          if (options) {
            this.companyOptions = options;

            let newOption = this.companyOptions.find(
              (option: companyOption) => this.selectedCountryId == option.countryId
            );
            if (newOption) {
              this.registrationOptionId = newOption.id;
              this.companyOption.next(newOption);
            }
          }
        })
    )

    this.subs.push(
      this.store
        .select((state) => state.shared.currentTemplateId)
        .subscribe((id) => {
          this.companyId = id || null;
        })
    )

    this.companyOption.subscribe((value) => {
      let jurForms: DropdownType1Eement[] | undefined =
        value?.legalForms.map<DropdownType1Eement>((form) => {
          console.log({ title: form.title });
          return { title: form.title, id: form.legalFormId };
        });
      if (jurForms) {
        this.jurisdictionalForms = [...jurForms];
      }

      this.capitalValues = value?.legalForms.map((form) => {
        return { id: form.legalFormId, capital: form.authorizedCapital };
      });
    });

    //selecting data from form
    this.numberOfDirectors = this.structureForm.value.numberOfDirectors;
    this.numberOfFounders = this.structureForm.value.numberOfFounders;
    //resize input
    // let inputAmountOfCapitalText = document.getElementById(
    //   'add-company-structure-capital-amount-text'
    // ); // get the input element
    // if (inputAmountOfCapitalText) {
    //   let input = inputAmountOfCapitalText as HTMLInputElement;
    //   input.style.width = `${
    //     this.structureForm.value.capital.toString().length - 0.1
    //   }ch`;

    //   this.structureForm.controls.capital.valueChanges.subscribe((val) => {
    //     input.style.width = `${input.value.length - 0.1}ch`;
    //     if (Number(input.value) < 20000) {
    //       this.structureForm.controls.capital.setValue('20000');
    //     }
    //   });

    //   this.structureForm.valueChanges.subscribe((val) => {
    //     this.setContinueBtn();
    //   });
    // }
  }

  ngOnDestroy() {
    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub: Subscription) => sub && sub.unsubscribe());
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.numberOfDirectors?.currentValue) {
      this.numberOfDirectors = changes.numberOfDirectors.currentValue;
      this.setContinueBtn();
    }
    if (changes.numberOfFounders?.currentValue) {
      this.numberOfFounders = changes.numberOfFounders.currentValue;
      this.setContinueBtn();
    }
    if (changes.selectedCountryId?.currentValue) {
      this.selectedCountryId = changes.selectedCountryId.currentValue;
      this.setContinueBtn();
    }
  }

  setJurisdictionalForm = (elem: string) => {
    let newCapitalInfo = this.getCapitalValues(elem);

    this.structureForm?.setValue({
      ...this.structureForm.value,
      jurisdictionalForm: elem,
      capital: newCapitalInfo.type ? newCapitalInfo.minSumm : newCapitalInfo.summ
    });

    setTimeout(() => {
      this.capital.nativeElement.style.width = `${newCapitalInfo.summ.length || 1}ch`;
    }, 10);
    this.setContinueBtn();
  };

  setNumberOfFounders = (elem: number) => {
    this.foundersChanged = true;
    this.structureForm?.setValue({
      ...this.structureForm.value,
      numberOfFounders: elem,
    });
  };
  setNumberOfDirectors = (elem: number) => {
    this.structureForm?.setValue({
      ...this.structureForm.value,
      numberOfDirectors: elem,
    });
  };

  setContinueBtn = () => {
    if (
      this.structureForm &&
      this.structureForm.value.capital &&
      this.structureForm.value.capital !== 0 &&
      this.structureForm.value.capital !== '0' &&
      this.structureForm.value.jurisdictionalForm &&
      this.structureForm.value.jurisdictionalForm !== '' &&
      this.structureForm.value.numberOfDirectors &&
      this.structureForm.value.numberOfDirectors !== 0 &&
      this.structureForm.value.numberOfFounders &&
      this.structureForm.value.numberOfFounders !== 0
    ) {
      this.continueButton = true;
    }
  };

  setDropdown(drop: string): void {
    this.dropdown = drop;
  }

  setCapital = (type: string, amount: string) => {
    let capitalInfo = this.getCapitalValues(this.structureForm.get('jurisdictionalForm')!.value);
    if (type == '+') {
      let value = this.structureForm.controls.capital.value;
      if (Number(value) + Number(amount) > capitalInfo.maxSumm) {
        return;
      }
      let capital = String(Number(value) + Number(amount));
      this.structureForm.controls.capital.setValue(capital);
      this.capital.nativeElement.style.width = `${capital.length || 1}ch`;
    } else if (type == '-') {
      let value = this.structureForm.controls.capital.value;
      if (Number(value) - Number(amount) < capitalInfo.minSumm) {
        return;
      }
      if (value > 0) {
        let capital = String(Number(value) - Number(amount));
        this.structureForm.controls.capital.setValue(capital);
        this.capital.nativeElement.style.width = `${capital.length || 1}ch`;
      }
    }
  };

  //submits structere and forfards step
  submitStructure = () => {
    let companyOption = this.companyOptions
      .find((option) => option.id == this.registrationOptionId)?.legalForms
      .find((lf) => lf.title == this.structureForm.value.jurisdictionalForm)

    // this.subs.push(
    //   this.store
    //     .select((state) => state.shared.currentTemplateId)
    //     .subscribe((id) => {
    //       if (!id) {
    //         this.store.dispatch(createTemplate({
    //           data: {
    //             registrationOptionId: this.registrationOptionId,
    //             legalFormId: this.structureForm.get('jurisdictionalForm')!.value,
    //             directorsCount: this.structureForm.get('numberOfDirectors')!.value,
    //             shareholdersCount: this.structureForm.get('numberOfFounders')!.value,
    //             authorizedCapital: this.structureForm.get('capital')!.value,
    //             processStep: 2
    //           }
    //         }));
    //       } else {
    //         this.store.dispatch(updateTemplate({
    //           data: {
    //             registrationOptionId: this.registrationOptionId,
    //             legalFormId: this.structureForm.get('jurisdictionalForm')!.value,
    //             directorsCount: this.structureForm.get('numberOfDirectors')!.value,
    //             shareholdersCount: this.structureForm.get('numberOfFounders')!.value,
    //             authorizedCapital: this.structureForm.get('capital')!.value,
    //             processStep: 2
    //           },
    //           id: id
    //         }));
    //       }
    //   })
    // );

    if (!this.companyId) {
      this.store.dispatch(createTemplate({
        data: {
          registrationOptionId: this.registrationOptionId,
          legalFormId: this.structureForm.get('jurisdictionalForm')!.value,
          directorsCount: this.structureForm.get('numberOfDirectors')!.value,
          shareholdersCount: this.structureForm.get('numberOfFounders')!.value,
          authorizedCapital: this.structureForm.get('capital')!.value,
          processStep: 2
        }
      }));
    } else {
      this.store.dispatch(updateTemplate({
        data: {
          registrationOptionId: this.registrationOptionId,
          legalFormId: this.structureForm.get('jurisdictionalForm')!.value,
          directorsCount: this.structureForm.get('numberOfDirectors')!.value,
          shareholdersCount: this.structureForm.get('numberOfFounders')!.value,
          authorizedCapital: this.structureForm.get('capital')!.value,
          processStep: 2
        },
        id: this.companyId
      }));
    }

    // this.editable == 'info'
    //   ? this.foundersChanged
    //     ? this.toSpecificStep(5)
    //     : this.previewStep()
    //   : this.nextStep();
  };

  private _listenForCreateSuccess() {
    this.subs.push(
      this.actions
        .pipe(ofType(createTemplateSuccess))
        .subscribe(() => {
          this.editable == 'info'
            ? this.foundersChanged
              ? this.toSpecificStep(5)
              : this.previewStep()
            : this.nextStep();
        })
    );
  }

  private _listenForUpdateSuccess() {
    this.subs.push(
      this.actions
        .pipe(ofType(updateTemplateSuccess))
        .subscribe(() => {
          this.editable == 'info'
            ? this.foundersChanged
              ? this.toSpecificStep(5)
              : this.previewStep()
            : this.nextStep();
        })
    );
  }

  public getStructureName(id: string) {
    return this.jurisdictionalForms.find(e => e.id === id)!.title;
  }

  public getCapitalValues(id: string) {
    let capitalInfo = this.capitalValues.find((e: any) => e.id === id);
    return capitalInfo && capitalInfo.capital;
  }

  public resizeInput(event: any) {
    let capitalInfo = this.getCapitalValues(this.structureForm.get('jurisdictionalForm')!.value);
    let value = event.target.value;

    if (value > capitalInfo.maxSumm) {
      value = capitalInfo.maxSumm;
    } else if (value < capitalInfo.minSumm) {
      value = capitalInfo.minSumm;
    }

    this.structureForm.controls.capital.setValue(value);

    this.capital.nativeElement.style.width = `${value.length || 1}ch`;
  }
}
